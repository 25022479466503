import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { User } from 'src/app/shared/User';
import { TasksService } from '../tasks.service';


@Component({
  selector: 'app-assignee',
  templateUrl: './assignee.component.html',
  styleUrls: ['./assignee.component.scss']
})
export class AssigneeComponent implements OnInit, OnChanges, OnDestroy {

  myControl = new FormControl();
  @Input('users') options: User[];
  @Output() updateAssignee  = new EventEmitter<User>();
  @Input('selectedUser') assignee: number;
  @Input('size') size: string;
  user: User;

  filteredOptions: Observable<User[]>;

  subscription: Subscription;
  subscriptions: Subscription[] = [];


  constructor(private tasksService: TasksService){ 
  }

  ngOnInit() {
    this.tasksService.sharedProject$.subscribe(s => {
      this.options = s;
    })

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.full_name),
        map(name => name ? this._filter(name) : this.options.slice())

      );

      this.setValue();
       
  }


  ngOnChanges(){

    this.setValue();
  }

  setValue(){
    // this.options = this.tasksService.users;

    if(this.assignee && this.options){
      this.user = this.options.filter(user=>{
        return user.id === this.assignee
      })[0];
      this.myControl.setValue( this.user);
    } else {
      this.user = null;
      this.myControl.setValue('');
    }
  }

  clear(){
    this.myControl.setValue('');
    this.updateAssignee.emit(null);
    this.assignee = null;
    this.user = null;
  }

 

  displayFn(user: User): string {
    return user && user.first_name ? user.first_name : '';
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();
    return this.options.filter(option => option.full_name.toLowerCase().indexOf(filterValue) === 0);
  }

  assigneeSelected(data){
    this.updateAssignee.emit(data.option.value);
    this.user = { ...data.option.value };
  }

  ngOnDestroy(){
    this.subscriptions.map(subscription => {
      if(subscription)
        subscription.unsubscribe();
    });
  }

}
