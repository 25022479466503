<app-header [brandname]="'Chat'"></app-header>
<div class="box" [ngClass]="{'dark': darkmode }">
  <div class="chatbox">
    <div class="chat">
      <div class="message" *ngFor="let message of messages">
        {{ message.author }}: {{ message.message}}
      </div>
    </div>
    <div class="chatinput">
      <input #message type=text placeholder="Type your message / command" (keydown.enter)="sendMsg()"/>
      <input type="button" value="Send" (click)="sendMsg()" />
    </div>
  </div>
</div>
