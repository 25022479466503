import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, shareReplay, finalize, catchError } from 'rxjs/operators';

import jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { LoaderService } from './loader.service';
import { User } from './User';
import { GlobalConstants } from '../shared/global-constants';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  api_url = GlobalConstants.apiUrl

  private apiRoot = this.api_url + 'api/token/';

  constructor(private http: HttpClient) { }

  currentUser: User;

  private setSession(authResult){

    const token = authResult.access;
    const payload = <JWTPayload> jwt_decode(token);
    const expiresAt = moment.unix(payload.exp);

    if(authResult.refresh){
      localStorage.setItem('refresh', authResult.refresh);
    }

    localStorage.setItem('token', authResult.access);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
  }

  get token(): string{
    return localStorage.getItem('token');
  }

  get refresh(): string{
    return localStorage.getItem('refresh');
  }

  login(username: string, password: string) {
    return this.http.post(
      this.apiRoot,
      { username, password }
    ).pipe(
      tap(response => this.setSession(response)),
      shareReplay(),
    );
  }

  private setCurrentUser(result){
    this.currentUser = result;
  }

  getCurrent(){
    return this.http.get(this.api_url + 'api/users/current/').pipe(
      tap(response => this.setCurrentUser(response)),
      shareReplay(),
    );
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('refresh');
  }

  refreshToken() {
    if (moment().isBetween(this.getExpiration().subtract(1, 'days'), this.getExpiration())) {
      return this.http.post(
        this.apiRoot.concat('refresh/'),
        { refresh: this.refresh }
      ).pipe(
        tap(response => this.setSession(response)),
        shareReplay(),
      ).subscribe();
    }

  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }


}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    //private loadingService: LoaderService
    ){}

  // private totalRequests = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.totalRequests++;
    // this.loadingService.setLoading(true);
    const token = localStorage.getItem('token');


    if (token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'Bearer '.concat(token))
      });

      return next.handle(cloned)
      // .pipe(
      //   finalize(() => {
      //     this.totalRequests--;
      //     if(this.totalRequests === 0){
      //       this.loadingService.setLoading(false); 
      //     }
      //   })
     
      // );

    } else {

      return next.handle(req)
      // .pipe(
      //   finalize(() => {
      //     this.totalRequests--;
      //     if(this.totalRequests === 0){
      //       this.loadingService.setLoading(false); 
      //     }
      //   })
      // );
    }
  }
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      this.authService.refreshToken();
      return true;
    } else {
      this.authService.logout();
      this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }
}


interface JWTPayload {
  user_id: number;
  username: string;
  email: string;
  exp: number;
}