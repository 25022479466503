import { Component, OnInit, HostListener, AfterViewChecked, Input, OnDestroy, OnChanges } from '@angular/core';
import { ThemeService } from '../shared/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-masonry-layout',
  templateUrl: './masonry-layout.component.html',
  styleUrls: ['./masonry-layout.component.scss']
})
export class MasonryLayoutComponent implements OnInit, AfterViewChecked, OnDestroy {

  darkmode: boolean;
  darkmodeSubscription: Subscription;

  @HostListener('window:resize') resize(){
    this.resizeAllGridItems();
  }

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.darkmodeSubscription = this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    });
  }

  ngOnDestroy(){
    this.darkmodeSubscription.unsubscribe();
  }

  ngAfterViewChecked(){
    this.resizeAllGridItems();
  }

  resizeGridItem(item){
    if(item.querySelector('.content')){
      let grid = document.getElementsByClassName("grid")[0];
      let rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
      let rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
      let rowSpan = Math.ceil((item.querySelector('.content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
      item.style.gridRowEnd = "span "+ rowSpan;
    }
  }

  resizeAllGridItems(){
    let allItems = document.getElementsByClassName("item");
    for(let x=0; x < allItems.length; x++){
      this.resizeGridItem(allItems[x]);
    }
  }

  resizeInstance(instance){
    let item = instance.elements[0];
    this.resizeGridItem(item);
  }

}
