<app-header [brandname]="'Photos'">
  <input type="text" #search [(ngModel)]="inputTerm" class="search" [ngClass]="{'dark': darkmode == true}" placeholder="Search"/>
</app-header>
<div class="layout" appScrollTracker #overlay1 appDnd (fileDropped)="onFileDropped($event)">
  <app-masonry-layout>
    <div class="item" *ngFor="let item of items | photoFilter:searchTerm" [ngClass]="{'dark': darkmode == true}">
      <div class="content">
        <app-photo [photo]="item" (click)="photoShow(item)"></app-photo>
        <app-photoData [photo]="item" (photoChanged)="UpdatePhotoChanged($event)" (photoDeleted)="PhotoDeleted($event)"></app-photoData>
      </div>
    </div>
  </app-masonry-layout>
</div>
<div class="plus" (click)="addPhotoForm()">
</div>