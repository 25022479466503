import { Injectable } from '@angular/core';
import { News } from './News';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../shared/global-constants';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  news: News[];

  api_url = GlobalConstants.apiUrl

constructor(private http: HttpClient) { }


  getNews():Observable<News[]>{
    return this.http.get<News[]>(this.api_url + 'api/news/');
  }

  getReuters():Observable<News[]>{
    return this.http.get<News[]>(this.api_url + 'news');
  }
}
