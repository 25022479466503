<div *ngIf="selectedTask" class="detail" appDnd (fileDropped)="onFileDropped($event)">

  <div class="header">
    <div class="left">
      <button type="button" class="btn btn-custom-uncheck btn-sm" (click)=toggleCompleted()
        [ngClass]="{'btn-custom-uncheck': !selectedTask.completed, 'btn-custom-check': selectedTask.completed}">
        <mat-icon fontSet="material-icons-outlined">done</mat-icon>{{ selectedTask.completed ? 'Completed' : 'Mark complete' }}
      </button>
    </div>
    <div class="right">
    <div class="btn-group ml-auto optionmenu" appDropdown>
        <button type="button" class="btn" id="dropdownMenuButton"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <mat-icon>more_vert</mat-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" >
          <li class="dropdown-item" (click)="deleteTask(selectedTask)">Delete task</li>
        </div>
      </div>
      <mat-icon fontSet="material-icons-outlined" class="hideButton" (click)="closeDetailWindow()">last_page</mat-icon>
    </div>
  </div>
  <div class="content" #scrollcontent>
    <div *ngIf="parentTask && parentTask.parent != null" class="urlToParent" (click)="changeSelectTask(parentTask)">
      <a class="urlToParent__span">{{parentTask.name}}<mat-icon class="icon__urlToParent" fontSet="material-icons-outlined">chevron_right</mat-icon></a> 
    </div>
    <div class="main">
      <app-textareaAutoGrow [inBoundData]="selectedTask.name" (outBoundData)="updateTaskName($event)"></app-textareaAutoGrow>
      <section class="section">
        <article class="assignee">
          <div class="label">Assignee</div>
          <div class="data">
            <app-assignee [users]="users" [selectedUser]="selectedTask.user" (updateAssignee)="onUpdateAssignee($event,selectedTask)"></app-assignee>
          </div>
        </article>
        <article class="due_date">        
          <div class="label">Due Date</div>
          <div class="data">
            <app-datepicker [task]="selectedTask" (taskEmitter)="UpdateDueDate()"></app-datepicker>
          </div>
        </article>
      </section>

      <ckeditor [editor]="editor" [config]="ckconfig" (change)="onDescriptionChange($event)" (ready)="onReady($event)" [(ngModel)]="selectedTask.description"></ckeditor>
    </div>

    <section class="mysubtasks" cdkDropList id="{{ selectedTask.id }}"  [cdkDropListData]="selectedTask.subtask"  (cdkDropListDropped)="dropItem($event)" 
    [cdkDropListConnectedTo]="getConnectedList()" [cdkDropListEnterPredicate]="samePredicate(selectedTask.id)" 
    >
      <div class="title">
        <span *ngIf="selectedTask.subtask && selectedTask.subtask.length > 1">Subtasks</span>
        <span *ngIf="selectedTask.subtask && selectedTask.subtask.length === 1">Subtask</span>
      </div>   
      <div class="mrow" *ngFor="let subtask of selectedTask.subtask" cdkDrag [cdkDragData]="subtask" >
          <div class="mcol mcol-auto taskname">
            <mat-icon class="drag" cdkDragHandle fontSet="material-icons-outlined">drag_indicator</mat-icon>
            <mat-checkbox class="item__check" [(ngModel)]="subtask.completed" (change)="updateSubTaskName(subtask)"></mat-checkbox>

            <div class="clickable">
              <div class="inputTaskname">
                <span  class="text">{{ subtask.name}}</span>
                <input appInputAutoGrow [(ngModel)]="subtask.name" (keyup)="updateSubTaskName(subtask)" on-focus="focusIn(subtask)" on-focusout="focusOut()" [attr.disabled]="subtask.completed ? 'disabled' : null" [ngClass]="{'completed': subtask.completed}" placeholder="New task..." maxlength="2000"/>
              </div>
              <div class="moreDetail" ><span (click)="changeSelectTask(subtask)">Detail <mat-icon>navigate_next</mat-icon></span> </div>
            </div>          
          </div>
      </div>
      <div class="mrow add">
        <div (click)="addSubtask(selectedTask.id, selectedTask.subtask.length)"><mat-icon>add</mat-icon> Add task...</div>
      </div>
    </section>
    <section class="attachments">
      <div class="attachment" *ngFor="let attachment of attachments" >
          <div class="file" (click)="onFileClick(attachment.id, attachment.name, false)">
            <span class="name"  title="{{attachment.name}}">{{attachment.name}}</span>
            <span class="mime" *ngIf="attachment.mime">{{attachment.mime}}</span>
          </div>
          <div class="options">
            <mat-select>
              <mat-option><span class="download" (click)="onFileClick(attachment.id, attachment.name, true)">Download</span></mat-option>
              <mat-option><span class="delete_attachment" (click)="onDeletePrivateFile(attachment.id, attachment.name)">Remove Attachment</span></mat-option>
            </mat-select>
          </div>

      </div>

    </section>
    <!---Container-Fluid--->
    <div class="comments">
      <section class="log_slide">
        <mat-slide-toggle [(ngModel)]="showLogs"color="primary">Show logs</mat-slide-toggle>
      </section>
      <section *ngFor="let comment of comments">
        <div *ngIf="comment.type === 'log-assign'" class="log" [ngClass]="{'hide': !showLogs}">
          <div>
            {{ currentUser.id === comment.user.id ? 'You' : comment.user.first_name }} 
            {{ comment.assigner ? 'assigned this task to ' : 'cleared the assignee '}}
            {{ comment.assigner ? 
                  comment.assigner.id === currentUser.id ? 
                  
                    comment.user.id === currentUser.id ? 'yourself' : 'you' 
                  
                  : comment.assigner.first_name + ' ' + comment.assigner.last_name
                
                  : ''            
            }} on
            {{ comment.dateadded | date:'MMM d, y h:mm:ss a'}}
          </div> 
        </div>
        <div *ngIf="comment.type === 'log-create'" class="log" [ngClass]="{'hide': !showLogs}">
            <div>{{ currentUser.id === comment.user.id ? 'You' : comment.user.first_name }} created at {{ comment.dateadded | date:'MMM d, y h:mm:ss a'}}</div>
        </div>
        <div *ngIf="comment.type === 'log-name'" class="log" [ngClass]="{'hide': !showLogs}">
            <div>{{currentUser.id === comment.user.id ? 'You' : comment.user.first_name }} updated task name on {{ comment.dateadded | date:'MMM d, y h:mm:ss a'}}</div>
        </div>
        <div *ngIf="comment.type === 'log-desc'" class="log" [ngClass]="{'hide': !showLogs}">
          <div>{{ currentUser.id === comment.user.id ? 'You' : comment.user.first_name  }} updated description on {{ comment.dateadded | date:'MMM d, y h:mm:ss a'}}</div>
        </div>
        <div *ngIf="comment.type === 'log-compl'" class="log" [ngClass]="{'hide': !showLogs}">
          <div>{{ currentUser.id === comment.user.id ? 'You' : comment.user.first_name }} {{ comment.comment == 'False' ? 'set this task to incomplete' : 'set this task to completed'}} on {{ comment.dateadded | date:'MMM d, y h:mm:ss a'}}</div>
        </div>
        <div *ngIf="comment.type === 'log-due'" class="log" [ngClass]="{'hide': !showLogs}">
          <div>{{ currentUser.id === comment.user.id ? 'You' : comment.user.first_name  }} {{ comment.comment === 'Clear' ? 'clear the due date' : 'set the due date to ' + (selectedTask.due | date:'MMM d, y') }} on {{ comment.dateadded | date:'MMM d, y h:mm:ss a'}}</div>
        </div>
        <div *ngIf="comment.type === 'comment'" class="comment">
          <!-- <div class="icon">CC</div> -->
          <app-initial-icon [user]="comment.user"></app-initial-icon>
          <div class="comment_inner">
            <div class="commentor_created">
              <span class="commentor">{{ comment.user.first_name}} {{ comment.user.last_name}}</span>
              <span class="comment_date" title="{{ comment.dateadded}}"> {{ comment.dateadded | elapseDateFormat }} </span>
            </div>
            <div class="comment_value">{{ comment.comment }}</div>
          </div>
        </div>
        <div class="separator" [ngClass]="{'hide': !showLogs && comment.type != 'comment'}"></div>
      </section>
    </div>
  </div> <!---Content-->
  <div class="comment_section" [ngClass]="{'focus': commentFocus}" >
    <div class="comment_controller">
      <textarea #comment placeholder="Ask a question or post an update..."  (mousedown)="commentFocusIn()" on-focus="commentFocusIn()" on-focusout="commentFocusOut();" maxlength="3000"></textarea>
      <div class="comment_button">
        <button type="button" class="btn btn-primary btn-sm" (mousedown)="addComment($event)">Comments</button>
      </div>
    </div>
  </div>
</div>
