import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ThemeService } from '../shared/theme.service';
import { Subscription } from 'rxjs';
import { MatDialog, MAT_DIALOG_SCROLL_STRATEGY_PROVIDER_FACTORY } from '@angular/material/dialog'
import { OptionsComponent } from './options/options.component';
import { NoopScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss']
})
export class ImagesComponent implements OnInit, OnDestroy {

  darkmode: boolean;
  subscriptions: Subscription[] = []
  
  text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ornare molestie arcu, vitae varius velit commodo placerat. In faucibus neque sollicitudin tristique hendrerit. Pellentesque scelerisque finibus blandit. Etiam ut ultricies arcu. Nunc eget cursus erat. Phasellus convallis felis felis, vel volutpat ipsum semper et. Etiam nulla massa, accumsan in mattis sit amet, ornare id diam. Fusce rutrum eros vel tortor dignissim dignissim. Fusce at consectetur neque. Pellentesque ut enim iaculis, rutrum turpis sed, pharetra sapien."

  items = [
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/300?random=1'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/100?random=2'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/400?random=3'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/430/300?random=4'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/100?random=5'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/300/340?random=6'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/500/300?random=7'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/800?random=8'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/200?random=9'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/300?random=10'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/400?random=11'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/500/300?random=12'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/300?random=13'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/100?random=14'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/400?random=15'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/430/300?random=16'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/100?random=17'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/340?random=18'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/500/300?random=19'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/100/100?random=20'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/200?random=21'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/300?random=22'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/400?random=23'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/500/300?random=24'},
  ]

  items_add = [
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/300?random=1'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/100?random=2'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/400?random=3'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/430/300?random=4'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/100?random=5'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/300/340?random=6'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/500/300?random=7'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/800?random=8'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/200?random=9'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/300?random=10'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/400?random=11'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/500/300?random=12'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/300?random=13'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/100?random=14'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/400?random=15'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/430/300?random=16'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/100?random=17'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/340?random=18'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/500/300?random=19'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/100/100?random=20'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/200/200?random=21'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/300?random=22'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/400/400?random=23'},
    {text: this.text.slice(0,this.getRandom()) ,image: 'https://picsum.photos/500/300?random=24'},
  ]
  
  constructor(private titleService: Title, private themeService: ThemeService, public dialog: MatDialog) { 

  }

  ngOnInit() {
    this.titleService.setTitle('Images');
    this.subscriptions.push(this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    }))

    this.subscriptions.push(this.themeService.scrollbottom.subscribe(result =>{
      this.items = [...this.items, ...this.items_add];
    }));
  }

  onLoadUpdateLayout(){}

  getRandom(){
    return Math.floor(Math.random() * (this.text.length - 30) ) + (this.text.length - 30) / 5;  
  }


  showOptions(){
          
    const dialogRef = this.dialog.open(OptionsComponent, {
      scrollStrategy: new NoopScrollStrategy(),    
      //scrollStrategy: this.overlay.scrollStrategies.reposition(),
      panelClass: this.darkmode === true ? 'darkmode' : '',

    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {

    }))
   

  }

  ngOnDestroy(){
    this.subscriptions.map(subscription =>
      subscription.unsubscribe()      
  )}

}
