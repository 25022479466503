import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TodoAppComponent } from './todo-app/todo-app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './shared/auth.service';
import { ImagesComponent } from './images/images.component';
import { TodoListComponent } from './todo-app/todo-list/todo-list.component';
import { StartComponent } from './todo-app/start/start.component';
import { TodoDetailComponent } from './todo-app/todo-detail/todo-detail.component';
import { PhotosComponent } from './photos/photos.component';
import { ChatComponent } from './chat/chat.component';
import { TictactoeComponent } from './tictactoe/tictactoe.component';



const routes: Routes = [
  { path: '', redirectTo: 'todo', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'todo', component: TodoAppComponent, canActivate: [AuthGuard], children: [
    { path: '', component: StartComponent},
    { path: ':projectid', component: TodoListComponent, children: [
        { path: '', component: StartComponent},
        { path: ':taskid', component: TodoDetailComponent}
    ]},
  ]},
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  { path: 'images', component: ImagesComponent},
  { path: 'photos', component: PhotosComponent},
  { path: 'tictactoe', component: TictactoeComponent},
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
