import { AfterViewInit, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ThemeService } from '../shared/theme.service';
import { Photo } from './photo';
import { PhotoService } from './photo.service';
import { fromEvent } from 'rxjs';
import { debounceTime} from 'rxjs/operators';
import { PhotoShowComponent } from './photoShow/photoShow.component';
import { AuthService } from '../shared/auth.service';
import { AddPhotoComponent } from './addPhoto/addPhoto.component';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import { Message } from './PhotoMessage'
import { GlobalConstants } from '../shared/global-constants';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
})
export class PhotosComponent implements OnInit, OnDestroy, AfterViewInit {
  
  //For Channel
  private myWebSocket: WebSocketSubject<any> 


  @ViewChild("search") search: ElementRef
  
  darkmode: boolean = true;
  private subscriptions: Subscription[] = [];
  items: Photo[] = [];
  next: string;
  previous: string;
  searchTerm: string = '';
  inputTerm: string = '';
  public screenWidth: any;
  public screenHeight: any;
  photoTypes: string[] = ['image/png','image/jpeg','image/jpg','image/gif','image/avif', 'image/svg+xml','image/bmp','image/tif']
  api_url = GlobalConstants.apiUrl

  constructor(private titleService: Title, private authService: AuthService, private themeService: ThemeService, public dialog: MatDialog, private photoService: PhotoService, private sanitizer: DomSanitizer) { 

    const token = localStorage.getItem('token');

    this.myWebSocket = webSocket(GlobalConstants.websocketurl + 'ws/photo/' + "?token=" + token);

    this.subscriptions.push(this.myWebSocket.asObservable().subscribe(    
      msg => //console.log('message received: ' + msg.message)
      // Called whenever there is a message from the server  
      {
        switch(msg.message){
          case 'CREATE': {
            this.getPhotoByID(msg.id, 'CREATE');
            break;
          }
          case 'UPDATE': {
            this.getPhotoByID(msg.id, 'UPDATE');
            break;
          }

          case 'DELETE': {
            this.items = this.items.filter(i => i.id !== msg.id)
            break;
          }
        }
      }
      ,err => console.log(err)
      // Called if WebSocket API signals some kind of error    
      ,() => console.log('complete') 
      // Called when connection is closed (for whatever reason)  
   ));

  }

  ngOnInit() {


    this.titleService.setTitle('Photos');
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.subscriptions.push(this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    }))

    this.getPhotos(this.api_url + "api/photo/")

    this.subscriptions.push(this.themeService.scrollbottom.subscribe(result =>{
      if(this.next != 'null'){
        this.getPhotos(this.api_url + this.next);
      } 
    }));

    this.subscriptions.push(this.photoService.NewPhoto$.subscribe((photo: Photo) => {
      this.sendMessage({ id: photo.id, message: "CREATE"})
      this.items = [photo, ...this.items]
    }));
  }

  sendMessage(msg: Message){
    this.myWebSocket.next({ id: msg.id, message: msg.message});
    this.refreshToken();  
  }

  getPhotoByID(id: number, action: string){
    this.photoService.getPhoto(id).subscribe(photo => {
      switch(action){
        case 'UPDATE': {
          this.items.forEach((element,index)=>{
            if(element.id == photo.id){
                this.items[index] = photo;
            }
          });
          break;
        }

        case 'CREATE': {
          this.items = [photo, ...this.items]
          break;
        }
      }
    });
  }

  UpdatePhotoChanged(photo: Photo){
    this.sendMessage({ id: photo.id, message: "UPDATE"});
    this.items = this.items.map(item => {
      return item.id === photo.id ? photo : item
    });
  }

  PhotoDeleted(photo: Photo){
    this.sendMessage({ id: photo.id, message: "DELETE"})
    this.items = this.items.filter(i => i.id !== photo.id);

  }

  onFileDropped(files: Array < any > ) {

    if(this.authService.isLoggedOut()){
      alert('Please log in before you upload a file.');
      return;
    }
    
    this.refreshToken();
    for (const item of files) {
      if(item.size > 3000000){
        alert("This image's size is " + item.size + ". Please upload an image less than 3 megabyte (3,000,000 bytes).")
      }
      else if(!this.photoTypes.includes(item.type)){

        alert("Please upload a supported image(jpg, jpeg, png).")
      } else {
        this.photoService.uploadImage(item).subscribe(photo => {
          this.photoService.NewPhoto.next({ "id": photo.id, 
            "name": photo.name, 
            "description": photo.description,
            "photo_url": photo.photo_url,
            "uploaded_at": photo.uploaded_at            
          });

        });
      }
    }

  }

  ngAfterViewInit(): void{
    this.subscriptions.push(fromEvent(this.search.nativeElement, 'keyup').pipe(debounceTime(1000))
    .subscribe(_ => {
      this.searchTerm = this.inputTerm
      this.refreshToken()
    }
    ));  
  }

  getPhotos(url: string){
    this.subscriptions.push(this.photoService.getPhotos(url).subscribe( data => {
      this.items = [...this.items, ...data['results']];  
      this.next = this.getPathname(data['next']);
      this.previous = data['previous'];
      this.refreshToken()   
    }));
  }

  getPathname(url: string){
    let el = document.createElement("a");
    el.href = url;
    return el.pathname.slice(1) + el.search;
  }

  refreshToken() {
    if (this.authService.isLoggedIn)
      this.authService.refreshToken();
  }

  onLoadUpdateLayout(){}

  addPhotoForm(){
    
    let dialogRef = this.dialog.open(AddPhotoComponent, {
      panelClass: this.darkmode === true ? 'darkmode' : ''
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
    }));
  }

  photoShow(photo: Photo){

    this.subscriptions.push(this.photoService.servePhoto(photo.id).subscribe(response => {
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let src = window.URL.createObjectURL(new Blob(binaryData, { type: dataType} )); 
      let combinedData = {...photo, "src": src}
      const img = new Image()
   
      img.src = src  

      img.onload =(e: any) =>{
        const width = e.target.naturalWidth;
        const height = e.target.naturalHeight;
   
        let option = 1;
        
        if( width < this.screenWidth && height < this.screenHeight){
          option = 1;
        }

        if(width > this.screenWidth && height < this.screenHeight ){
          option = 2;
        }

        if(width < this.screenWidth && height > this.screenHeight){
            option = 3
        }

        if(width > this.screenWidth && height > this.screenHeight){
          if(width < height){
            if(this.screenWidth < 600){
              option = 7
            } else {
              option = 4
            }
          } else {
            if(this.screenWidth < 600){
              option = 5
            } else {
              option = 6
            }
          }
        }

        let dialogRef

        if(option === 1){
          dialogRef = this.dialog.open(PhotoShowComponent, {
            panelClass: this.darkmode === true ? 'darkmode' : '',
            data: combinedData
          });
        } else if(option == 2) {
          dialogRef = this.dialog.open(PhotoShowComponent, {
            panelClass: this.darkmode === true ? 'darkmode' : '',
            maxWidth: '85vw',
            data: combinedData
          });
        }else if(option == 3) {
          dialogRef = this.dialog.open(PhotoShowComponent, {
            panelClass: this.darkmode === true ? 'darkmode' : '',
            height: '85vh',
            maxHeight: '85vh',
            data: combinedData
          });
        } else if(option === 4) {
          dialogRef = this.dialog.open(PhotoShowComponent, {
            panelClass: this.darkmode === true ? 'darkmode' : '',
            height: '85vh',
            maxHeight: '85vh',
  
            data: combinedData
          });
        } else if(option == 5) {
          dialogRef = this.dialog.open(PhotoShowComponent, {
            panelClass: this.darkmode === true ? 'darkmode' : '',
            width: '90vw',
            maxWidth: '90vw',
            data: combinedData
          });
        } else if(option == 6) {
          dialogRef = this.dialog.open(PhotoShowComponent, {
            panelClass: this.darkmode === true ? 'darkmode' : '',
            width: '100vh',
            maxWidth: '120vh',
            data: combinedData
          });
        } else {
          dialogRef = this.dialog.open(PhotoShowComponent, {
            panelClass: this.darkmode === true ? 'darkmode' : '',
            width: '85vw',
            maxWidth: '85vw',
            data: combinedData
          });
        } 

        this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {

        }));
      }


      
    }));
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }

  ngOnDestroy(){
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    })
  }

}
