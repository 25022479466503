<app-masonry-layout>
    <div class="item" *ngFor="let item of items | newsFilter:inputTerm" [ngClass]="{'dark': darkmode == true}">
      <div class="content">
        <a target="_blank" href="{{ item.url}}">
          <img *ngIf="item.image" src="{{item.image}}" (load)="onLoadUpdateLayout()" appLazyLoad/>
          <h5 class="title">{{item.title}}</h5>
          <div class="summary" *ngIf="item.description">{{item.description}}</div>
        </a>
      </div>
    </div>
</app-masonry-layout>
