import { Pipe, PipeTransform } from '@angular/core';
import { Photo } from './photo';

@Pipe({
  name: 'photoFilter'
})
export class PhotoFilterPipe implements PipeTransform {

  transform(photos: Photo[], search: string): any {

    if(!photos || !search || search.length < 3){
      return photos
    }

    let terms = search.toLowerCase().trim().split(" ")

    return photos.filter(photo => {
        //return (terms.every(t => photo.name.toLowerCase().split(" ").includes(t))) || (terms.every(t => photo.description.toLowerCase().split(" ").includes(t)))
        return (terms.some(t => photo.name && photo.name.toLowerCase().indexOf(t) !== -1)) || (terms.some(t => photo.description && photo.description.toLowerCase().indexOf(t) !== -1))
    }
    );

 
      
  }

}
