import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TasksService } from '../tasks.service';
import { Project } from '../Project';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-todo-side-menu',
  templateUrl: './todo-side-menu.component.html',
  styleUrls: ['./todo-side-menu.component.scss']
})
export class TodoSideMenuComponent implements OnInit, OnDestroy {
  projectid: number;
  subjectProjectNameChange: Subject<Project> = new Subject();

  projects: Project[] = [];
  projectSelected: Project;


  subscription: Subscription;
  subscriptions: Subscription[] = [];

  constructor(private tasksService: TasksService, private authService: AuthService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {

    this.subscription = this.tasksService.getProject().subscribe(data =>{
      this.refreshToken();
      this.projects = data;
      this.tasksService.projects = this.projects;

    })
    this.subscriptions.push(this.subscription);


    this.subscription = this.subjectProjectNameChange.pipe(
      debounceTime(500)
    ).subscribe(project => {
      this.refreshToken();

      this.subscription = this.tasksService.updateProject(project).subscribe(result =>{
      });
      this.subscriptions.push(this.subscription);
    });

    this.subscriptions.push(this.subscription);

    this.subscription = this.tasksService.selectedProjectId$.subscribe(id => {

      if(this.projects.length === 0){
        this.subscription = this.tasksService.getProject().subscribe(data =>{
          this.refreshToken();
          this.projects = data;

  
          this.projects.map(project => {
            if(project.id == id){
              this.selectedProject(id);
            }
          })

        })
        this.subscriptions.push(this.subscription);

      } else {
        this.projects.map(project => {
          if(project.id == id){
            this.selectedProject(id);
          }
        })
      }

    });

    this.subscriptions.push(this.subscription);

  }

  addProject(){
    this.refreshToken();
    this.subscription = this.tasksService.createProject().subscribe(result => {
      this.projects.push({id: result.id, name: result.name, completed: result.completed, creator: result.creator, shared: result.shared, isCreator: 1})
    })
    this.subscriptions.push(this.subscription);
  }

  deleteProject(project: Project){
    let permission: boolean = confirm("Are you sure you want to remove this project?")

    if(permission){
      this.refreshToken();
      this.tasksService.deleteProject(project.id).subscribe(result => {
        this.projects = this.projects.filter(proj => proj.id !== project.id)
        this.tasksService.selectedProject.next(null); 
        this.router.navigate(['../'], {relativeTo: this.route});
      });

    }


  }

  selectedProject(id: number){
    this.projects.map(project => {
      if(project.id === +id){
        project.selected = true;
        this.tasksService.selectedProject.next(project); 

      } else {
        project.selected = false;  
      }

      this.tasksService.isDetailWindowOpened.next(false);
      this.tasksService.sidenav.next(true);

    }); 

  }

  ngOnDestroy(){
    this.subscriptions.map(subscription =>{
      if(subscription)
        subscription.unsubscribe();
    });
  }

  onKeyUponProjectName(project: Project){
    this.subjectProjectNameChange.next(project);
  }

  refreshToken(){
    if(this.authService.isLoggedIn)
      this.authService.refreshToken();
  }

}
