import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/auth.service';
import { ThemeService } from 'src/app/shared/theme.service';
import { Photo } from '../photo';
import { PhotoService } from '../photo.service';
import { PhotoOptionsComponent } from '../photoOptions/photoOptions.component';

@Component({
  selector: 'app-photoData',
  templateUrl: './photoData.component.html',
  styleUrls: ['./photoData.component.scss']
})
export class PhotoDataComponent implements OnInit, OnDestroy{

  @ViewChild('menu', {static: true}) menu: MatMenu
  @Input() photo: Photo;
  @Output() photoChanged: EventEmitter<Photo> = new EventEmitter();
  @Output() photoDeleted: EventEmitter<Photo> = new EventEmitter();
  darkmode: boolean = true;
  isLoggedIn = false;

  subscriptions: Subscription[] = []
  constructor(public dialog: MatDialog, private themeService: ThemeService, private photoService: PhotoService, private authService: AuthService) { }

  ngOnInit() {

    this.subscriptions.push(this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    }))

    this.isLoggedIn = this.authService.isLoggedIn();

  }

  delete(photo: Photo){
    let answer = confirm("Are you sure you want to remove this picture?")
    if(answer) {
      this.photoService.deletePhoto(photo).subscribe(result => {
        this.photoDeleted.emit(this.photo);
      })
    }
    this.refreshToken();
  }

  showOptions(photo: Photo){          
    const dialogRef = this.dialog.open(PhotoOptionsComponent, {
      panelClass: this.darkmode === true ? 'darkmode' : '',
      data: {
        ...photo
      }
    });

    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.photo = result;
        this.photoService.updatePhoto(this.photo).subscribe(result => {
          this.photoChanged.emit(this.photo);
        });

      }
      this.refreshToken();
    }));
  }

  ngOnDestroy(){
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

  refreshToken() {
    if (this.authService.isLoggedIn)
      this.authService.refreshToken();
  }

}
