import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ThemeService } from 'src/app/shared/theme.service';
import { Photo } from '../photo';

@Component({
  selector: 'app-photoOptions',
  templateUrl: './photoOptions.component.html',
  styleUrls: ['./photoOptions.component.scss']
})
export class PhotoOptionsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = []
  darkmode: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public photo: Photo, public dialogRef: MatDialogRef<PhotoOptionsComponent>
  , private themeService: ThemeService) { }

  ngOnInit() {
    this.subscriptions.push(this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    }))
  }

  onUpdateDescription(value: string){
    this.photo.description = value;
  }

  ngOnDestroy(){
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
