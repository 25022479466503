import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';
import { TasksService } from '../../app/todo-app/tasks.service'
import { ResizeObserver } from 'resize-observer';

@Directive({
  selector: '[appInputResize]'
})
export class InputResizeDirective  {

  parent: any;
  child: any;
  value_length: any;

  windowSizeChangeSubscription;
  myObserver;

  constructor(private el: ElementRef, public renderer: Renderer2, private taskService: TasksService) { 
    this.myObserver = new ResizeObserver(entries => { 
      this.resize();
    }); 
    this.myObserver.observe(this.el.nativeElement.parentElement); 
  }


  @HostListener('keyup') onKeyUp(){
    this.resize();
  }


  @HostListener('hover') onHover(){
    this.resize();
  }



  resize(){

    this.parent = this.el.nativeElement.parentElement.offsetWidth - 50;
    this.child = this.el.nativeElement.offsetWidth;
    this.value_length = this.el.nativeElement.value.length;

    

    if (this.value_length < 20){
      this.value_length = 20;
    }

    if (this.child < this.parent){
      this.renderer.setStyle(this.el.nativeElement,'width', this.value_length + 'ch');
    }else {
      this.renderer.setStyle(this.el.nativeElement,'width', '100%');
    }

  }

}
