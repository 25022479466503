import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/auth.service';
import { ThemeService } from 'src/app/shared/theme.service';
import { Photo } from '../photo';
import { PhotoService } from '../photo.service';

@Component({
  selector: 'app-addPhoto',
  templateUrl: './addPhoto.component.html',
  styleUrls: ['./addPhoto.component.scss']
})
export class AddPhotoComponent implements OnInit, OnDestroy {

  darkmode: boolean;
  subscriptions: Subscription[] = []

  name: string = '';
  description = ''; 
  @ViewChild('file') file: ElementRef;
  photo: Photo;
  photoTypes: string[] = ['image/png','image/jpeg','image/jpg','image/gif','image/avif', 'image/svg+xml','image/bmp','image/tif']

  constructor(private themeService: ThemeService, private photoService: PhotoService, private authService: AuthService) { }


  ngOnInit() {
    this.subscriptions.push(this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    }))
  }

  upload(){

    if(this.authService.isLoggedOut()){
      alert('Please log in before you upload a file.');
      return;
    }

    if(!this.file.nativeElement.files[0]){
      alert('Please select a file before you submit.')
    } else if (!this.photoTypes.includes(this.file.nativeElement.files[0].type)){

      alert("Please upload a supported image(jpg, jpg, png).")
    }
    else if (this.file.nativeElement.files[0].size > 3000000){
      alert("This image's size is " + this.file.nativeElement.files[0].size + ". Please upload an image less than 3 megabyte (3,000,000 bytes).")
    } else {
      this.photoService.uploadImage(this.file.nativeElement.files[0], this.name, this.description).subscribe(
        result => {
          this.photo = { "id" : result.id, 
              "name": result.name, 
              "description": result.description,
              "photo_url": result.photo_url,
              "uploaded_at": result.uploaded_at            
            }
          this.photoService.NewPhoto.next(this.photo)      
        });
    }

  }


  ngOnDestroy(){
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

}
