import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Task } from '../../todo-app/Task'

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  @Input('task') task: Task;
  @Input('size') size: string;
  @Input('hideIcon') hideIcon: boolean = false;
  @Output() taskEmitter:EventEmitter<Task> = new EventEmitter<Task>(); 
  constructor() { }

  ngOnInit() {

  }

  convertDateToText(value: string){
    var datePipe = new DatePipe("en-US");
    var date_value = datePipe.transform(value, 'MMM d');
    var date = new Date(value),
    diff = ((date.getTime() - (new Date()).getTime()) / 1000),
    daydiff = Math.ceil(diff / 86400);

    if (isNaN(daydiff))
      return '';

    return daydiff < 0 && "Due" ||    
    daydiff == 0 && "Today" ||
    daydiff == 1 && "Tmw" ||
    daydiff < 7 && datePipe.transform(value, 'EEE') ||

    // daydiff < 14 && daydiff + " days" ||
    // daydiff < 14 && Math.floor(daydiff / 7) + "week" ||
    // daydiff < 31 && Math.floor(daydiff / 7) + " weeks" ||
    date_value;
  }

  clear(event: Event){
    event.stopPropagation;
    this.task.due = null
    this.taskEmitter.emit(this.task);
  }

  focusIn(){

  }

  focusOut(){

  }

  onDate(event){
   this.task.due = event;
   this.taskEmitter.emit(this.task);
  }

}
