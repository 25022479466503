<div class="header" [ngClass]="{'dark': darkmode,'todo': brandname == ''}">
  <div class="logo">{{ brandname }}</div>
  <div class="menu">
    <div class="options">
      <ng-content></ng-content>
    </div>
    <div class="background-check" title="Dark Mode enabled between 6pm and 6am">
      <input class="form-check-input"  type="checkbox" [checked]="darkmode" (change)="onCheckBackgroundColor($event)"  id="backgroundcheckbox">
      <label class="form-check-label" for="backgroundcheckbox">
        Dark Mode
      </label>
    </div>

    <app-menu [darkmode]="darkmode && brandname != ''"></app-menu>
  </div>
</div>