import { Component, Input, OnInit, OnDestroy, AfterViewChecked, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { DatePipe } from '@angular/common';
import {CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag} from '@angular/cdk/drag-drop';

import { Task } from '../Task';
import { TasksService} from '../tasks.service'
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { User } from '../../shared/User';
import { Project } from '../Project';
import { AuthService } from 'src/app/shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';



@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss']

})
export class TodoListComponent implements  OnInit, OnDestroy, AfterViewChecked {
  @Input() taskid: number;
  tasks: Task[];
  projects: Project[];
  showDetailWindow: boolean = false;

  subjectNameChange: Subject<Task> = new Subject();

  selectedTask: Task;
  focus: boolean = false;
  hover: boolean = false;
  @ViewChild('scrollcontent') scrollcontent: ElementRef;
  scrollToTop: number = 0;
  showIncomplete: boolean = false;

  subscription: Subscription;
  subscriptions: Subscription[] = [];
  users: User[];

  project: Project;
  projectid: number;
  allowedusers: User[];
  founduser: boolean;


  constructor(private tasksService: TasksService, private authService: AuthService, private renderer: Renderer2, private route: ActivatedRoute, private router: Router){ 
    this.users = this.tasksService.users;
  }


  ngOnDestroy(){
    this.subscriptions.map(subscription => {
      if(subscription)
        subscription.unsubscribe();
    });

  }

  ngAfterViewChecked(){
    if(this.scrollcontent && this.scrollToTop < 1){
      this.renderer.setProperty(this.scrollcontent.nativeElement, 'scrollTop', 0);
      this.scrollToTop++;
    }
  }


  ngOnInit(){

    this.subscription = this.tasksService.detailWindowOpened$.subscribe( status => {
      this.showDetailWindow = status;
    });    
    this.subscriptions.push(this.subscription);

    this.subscription = this.subjectNameChange.pipe(debounceTime(700)).subscribe(task => {
      this.updateTask(task);
    });
    this.subscriptions.push(this.subscription);
 

    this.subscription = this.tasksService.selectTask$.subscribe(task => {

      try{
        task['description'] = decodeURIComponent(task['description'])
      } catch (e){}
      this.selectedTask = task

      this.tasks = this.tasksService.tasks;
    });
    this.subscriptions.push(this.subscription);
  
    this.route.params.subscribe(params => {
      this.getUsers();
      this.projectid = +params['projectid']
      this.subscription = this.tasksService.getProject().subscribe(data =>{
        this.projects = data;
        this.project = null;
        let status = this.projects.some(project => project.id === this.projectid) 
        if(status){

          this.projects.map(p => { if(p.id === this.projectid) this.project = p})

          this.getUsers();
          this.getTasksbyProjectID(this.projectid);
          this.tasksService.selectedProjectId.next(this.projectid);
        } else {
          this.router.navigate(['../'], {relativeTo: this.route});
        }
        this.tasksService.isDetailWindowOpened.next(false);

      })
      this.subscriptions.push(this.subscription);
      


    });
  }


  getUsers(){
      this.tasksService.getUsers().subscribe(result=>{

        this.users = result.map(u => {
          return this.project.shared.includes(u.id) ? { ...u, permission: true } : { ...u, permission: false}
        })
        this.tasksService.users = this.users;

        this.allowedusers = this.users.filter(u => u.permission === true);
        this.tasksService.allowedusers = this.allowedusers;
        this.tasksService.shareUpdated.next(this.allowedusers);   
      })

  }

   
  getTasksbyProjectID(projectid: number){
    this.refreshToken();
    this.subscription = this.tasksService.getTasks(projectid).subscribe(data => {
      this.tasks = data;
      this.tasksService.tasks = this.tasks;
      this.updateTaskToShow(this.tasks);
    });
    this.subscriptions.push(this.subscription);  
  }


  updateTaskToShow(tasks: Task[]){
    tasks.map(task => {
        if(task.parent != null && task.completed === false){
          task.show = true;
        }  

        if(task.subtask && task.subtask.length > 0){
          this.updateTaskToShow(task.subtask);
        }
      });
  }


  updatePermission(user: User){
    this.founduser = false;
    this.checkUserInTasks(this.tasks, user.id);

    if(this.project.shared.includes(user.id) && this.project.shared.length >= 1){

      if (this.founduser){
        alert(user.first_name + ' is assigned to one of the tasks.')
      }else if(user.id !== this.project.creator){
        this.project.shared = this.project.shared.filter(id => id !== user.id)
        this.updateUsersProjectPermission();
      
      }else{
        alert(user.first_name + ' can not be removed. User is the creator of this project')
      }

    } else {
      this.project.shared.push(user.id);
      this.updateUsersProjectPermission();
    }

  }

  updateUsersProjectPermission(){
    this.users = this.users.map(u => {
      return this.project.shared.includes(u.id) ? { ...u, permission: true } : { ...u, permission: false}
    })

    this.allowedusers = this.users.filter(u => u.permission === true);
    this.tasksService.updateProject(this.project).subscribe();
    this.tasksService.shareUpdated.next(this.allowedusers);


  }

  checkUserInTasks(tasks: Task[], id: number){

   tasks.map(task => {

      if(task.user === id && task.parent !== null){
        this.founduser = true;  

      } else if( task.subtask && task.subtask.length > 0 ){
        this.checkUserInTasks(task.subtask, id);
      } 
    });


  }



  onUpdateAssignee(user, task){
      if (user !== null){
        task.user = user.id
      } else{
        task.user = null
      }
      this.subscription = this.tasksService.updateTask(task).subscribe(result=>{
        this.tasksService.getComment.next(this.selectedTask);
      })
      this.subscriptions.push(this.subscription);   

    }

  addSection(){
    this.refreshToken();
    this.tasksService.addSection(this.tasks.length, this.projectid).subscribe(result => {
      this.tasks.push(result);
    });

  }

  deleteSection(task: Task){

    let permission: boolean; 
    permission = confirm("Deleting this section will delete all tasks and subtasks. Are you sure?");
    if (permission === true){
      this.refreshToken();
      this.tasksService.deleteSection(task).subscribe(result =>{
        this.tasks = this.tasks.filter(t => t.id !== task.id);
      });
    }
  }

  toggleView(){
    this.refreshToken();
    this.showIncomplete = !this.showIncomplete;
  }

  addSubtask(parentId: number, count: number){
    this.refreshToken();
    this.tasksService.addTask(parentId, count, this.projectid).subscribe(result =>{
     this.appendTask(this.tasks, result);
    });
  }

  appendTask(tasks: Task[], ntask: Task){

    this.tasks.map(task => {
      return task.id === ntask.parent ? task.subtask.push({...ntask, show: true, completed: false}) : task;
    })

  }

  onKeyUp(task: Task){
    this.subjectNameChange.next(task);
  }

  onChange(task: Task){

    this.updateTask(task);
    if(task.completed && this.showIncomplete && task === this.selectedTask){
      this.tasksService.isDetailWindowOpened.next(false);
    }

  }

  updateTask(task: Task){
    this.refreshToken();
    this.subscription = this.tasksService.updateTask(task).subscribe(result => {
      this.tasksService.getComment.next(this.selectedTask);
    })
    this.subscriptions.push(this.subscription);
  }


  focusIn(task: Task){
    this.refreshToken();
    this.focus = true;
    this.tasksService.selectTask.next(task);
    if(this.showDetailWindow){
      this.router.navigate([task.id], {relativeTo: this.route});
    }
    

  }

  focusOut(){
    this.refreshToken();
    this.focus= false;
  }

  UpdateDueDate(task: Task){
    this.updateTask(task);
  }


  dropItem(event: CdkDragDrop<string[]>) {
    this.refreshToken();
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      event.container.data.forEach((task,index) => {
        task['order'] = index;
      });

      this.tasks.map(task => {
        if (task.id.toString() == event.container.id){
          task.subtask.map(st =>{
            this.updateTask(st);
          })
        }
      })

    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

        event.container.data.forEach((task,index) => {
          task['order'] = index;
          task['parent'] = +event.container.id;

        });

        event.previousContainer.data.forEach((task,index) => {
          task['order'] = index;
          task['parent'] = +event.previousContainer.id;
        });

        this.tasks.map(task => {
          if (task.id.toString() == event.container.id){
            task.subtask.map(st =>{
              this.updateTask(st);
            })
          }
        })

        this.tasks.map(task => {
          if (task.id.toString() == event.previousContainer.id){
            task.subtask.map(st =>{
              this.updateTask(st);
            })
          }
        })


    }
  }

  getConnectedList(): any[] {
    let list = this.tasks.map(x => `${x.id}`);
    if(this.selectedTask){
      list.push(this.selectedTask.id.toString());
    }
    return list;
  }

  dropGroup(event: CdkDragDrop<string[]>) {
    this.refreshToken();

    if (event.previousContainer === event.container) {
      moveItemInArray(this.tasks, event.previousIndex, event.currentIndex);

      event.container.data.forEach((task,index) => {
        task['order'] = index;
      })

      this.tasks.map(task => {
        this.updateTask(task);
      });
    }  
  }

  sameContainerOnly(item: CdkDrag<number>){
    return false;
  }

  refreshToken(){
    if(this.authService.isLoggedIn)
      this.authService.refreshToken();
  }
}
