<div class="datepicker">
  <div class="noduedate" [ngClass]="{'widen' : !task.due}">
    <mat-icon class="circled-icon" [ngClass]="{'hide' : task.due && hideIcon, 'notvisible': hideIcon, 'small' : size == 'small'}" fontSet="material-icons-outlined">
      event</mat-icon>
    <input #date class="datepicker_input" [ngClass]="{'hide': task.due}" [matDatepicker]="picker" (ngModelChange)="onDate($event)"
      (click)="picker.open()" [(ngModel)]="task.due" on-focus="focusIn()" on-focusout="focusOut();">
    <mat-datepicker #picker></mat-datepicker>
  </div>
  <div class="yesduedate" [ngClass]="{'widen' : task.due}">
    <div class="datepicker_span"
    [ngClass]="{'hide' : !task.due, 'green': (convertDateToText(task.due) === 'Tmw' || convertDateToText(task.due) === 'Today') && task.due, 'red': convertDateToText(task.due) === 'Due', 'large': size != 'small' }" >{{ task.due | niceDateFormatPipe}}
    </div>
    <mat-icon fontSet="material-icons-outlined" class="clearIcon" [ngClass]="{'notvisible': 1 == 1}" (click)="clear($event)">clear 
    </mat-icon>
  </div>

</div>
