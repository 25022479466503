<div class="main">
  <div class="main-list" [ngClass]="{'show': showDetailWindow}">
    <div *ngIf="tasks" class="header">
      <div class="left">
        <button type="button" class="btn btn-custom-button btn-sm" (click)="addSection()">
          <mat-icon fontSet="material-icons-outlined">add_task</mat-icon>Add section
        </button>
        
        <div class="btn-group ml-1 shareMenu" appDropdown>
          <button type="button" class="btn btn-custom-button btn-sm dropdown-toggle" id="dropdownSharedMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <mat-icon fontSet="material-icons-outlined">supervised_user_circle</mat-icon> Share
          </button>      
          <div *ngIf="users" class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownSharedMenuButton">
            <li class="dropdown-item" *ngFor="let user of users" (click)="updatePermission(user)">
              <span [ngClass]="{'permitted' : user.permission}" >{{ user.full_name }}</span> 
            </li>
          </div>

        </div>
      </div>
      <div class="right">
        <div class="btn-group ml-auto optionmenu" appDropdown>
          <button type="button" class="btn" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <mat-icon fontSet="material-icons-outlined">more_vert</mat-icon>
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <li class="dropdown-item" (click)="toggleView()">{{ showIncomplete == true ? 'Show all' : 'Show Incomplete' }}
            </li>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="tasks" class="grid" >
      <section>
        <div class="mrow head">
          <div class="mcol mcol-auto">Task name</div>
          <div class="mcol mcol-fixed">Assignee</div>
          <div class="mcol mcol-fixed">Due</div>
        </div>
      </section>
      <main cdkDropList (cdkDropListDropped)="dropGroup($event)" [cdkDropListData]="tasks"  [cdkDropListEnterPredicate]="sameContainerOnly" #scrollcontent>
        <section cdkDropList [cdkDropListConnectedTo]="getConnectedList()" [cdkDropListData]="task.subtask" (cdkDropListDropped)="dropItem($event)"   id="{{ task.id }}" cdkDrag [cdkDragData]="tasks" *ngFor="let task of tasks" cdkDragLockAxis="y">
          <div class="mrow">
            <div class="mcol mcol-auto sectionName">
              <mat-icon class="drag" cdkDragHandle fontSet="material-icons-outlined">drag_indicator</mat-icon>
              <div class="inputTaskname">
                <span class="text">{{ task.name}}</span>
                <input appInputAutoGrow [(ngModel)]="task.name" (keyup)="onKeyUp(task)" maxlength="255" placeholder="New Section"/>
              </div>
              <span class="removeSection" (click)="deleteSection(task)">
                <mat-icon ontSet="material-icons-outlined">remove_circle_outline</mat-icon>
              </span>
            </div>
          </div>
          <div class="mrow2" *ngFor="let subtask of task.subtask" [ngClass]="{'selected': (subtask === selectedTask) && selectedTask, 'focus': (subtask === selectedTask) && focus, 'detail': showDetailWindow}">
            <div class="mrow"   cdkDrag [cdkDragData]="subtask"  *ngIf="(showIncomplete && !subtask.completed) || (!showIncomplete)" 
            routerLinkActive="selected" [ngClass]="{'focus': (subtask === selectedTask) && focus, 'detail': showDetailWindow}">
                <div class="mcol mcol-auto taskname">
                  <mat-icon class="drag" cdkDragHandle fontSet="material-icons-outlined">drag_indicator</mat-icon>
                  <mat-checkbox class="item__check" [(ngModel)]="subtask.completed" (change)="onChange(subtask)"></mat-checkbox>
                  <div class="clickable">
                    <div class="inputTaskname">
                      <span  class="text">{{ subtask.name}}</span>
                      <input appInputAutoGrow [(ngModel)]="subtask.name" (keyup)="onKeyUp(subtask)" on-focus="focusIn(subtask)" on-focusout="focusOut()" [attr.disabled]="subtask.completed ? 'disabled' : null" [ngClass]="{'completed': subtask.completed}" placeholder="New task..." maxlength="2000"/>
                    </div>
                    <div class="moreDetail" routerLink="{{subtask.id}}">        
                      <span [ngClass]="{'hide': showDetailWindow}" > Detail</span> <mat-icon>navigate_next</mat-icon>
                    </div>
                  </div>            
                </div>
                <div class="mcol mcol-fixed">
                  <app-assignee [users]="allowedusers" [size]="'small'"[selectedUser]="subtask.user" (updateAssignee)="onUpdateAssignee($event,subtask)"></app-assignee>
                </div>
                <div class="mcol mcol-fixed">
                  <app-datepicker [size]="'small'"[task]="subtask" [hideIcon]="true" (taskEmitter)="UpdateDueDate(subtask)"></app-datepicker>
                </div>
            </div>
          </div>
          <div class="mrow add">
            <div (click)="addSubtask(task.id, task.subtask.length)"><mat-icon>add</mat-icon> Add task...</div>
          </div>
        </section>

      </main>
    </div>
  </div>
  <div class="main-detail" [ngClass]="{'show': showDetailWindow}">
    <router-outlet></router-outlet>
  </div>
</div>

  
