import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-photoShow',
  templateUrl: './photoShow.component.html',
  styleUrls: ['./photoShow.component.scss']
})
export class PhotoShowComponent implements OnInit {

  subscriptions: Subscription[] = [];
  src;

  constructor(
    public dialogRef: MatDialogRef<PhotoShowComponent>,
    @Inject(MAT_DIALOG_DATA) public photo,
    private sanitizer: DomSanitizer
    ) { }

  ngOnInit() {
    this.src = this.sanitizer.bypassSecurityTrustUrl(this.photo.src);
  }

  onLoadUpdateLayout(){}

}
