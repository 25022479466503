import { Directive, ElementRef, Renderer2, HostListener, AfterContentChecked } from '@angular/core';
import { ResizeObserver } from 'resize-observer';


@Directive({
  selector: '[appInputAutoGrow]'
})
export class InputAutoGrowDirective implements AfterContentChecked {

  myObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) { 

    this.myObserver = new ResizeObserver(entries => { 
      this.autoGrow();
    }); 
    this.myObserver.observe(this.el.nativeElement.parentElement); 

  }

  @HostListener('keyup') onKeyUp(){
    this.autoGrow()
  }

  ngAfterContentChecked(){
    // this.autoGrow();
  }


  autoGrow(){
    const input = this.el.nativeElement;
    const span = this.el.nativeElement.parentElement.querySelector('span');
    this.renderer.setStyle(input, 'width', '0px');
    this.renderer.setStyle(input, 'width', span.scrollWidth + 'px');

  }

}
