import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ThemeService } from 'src/app/shared/theme.service';
import { Subscription } from 'rxjs';
import { News } from './News';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {
  @Input('items') items: News[];
  @Input('inputTerm') inputTerm: string;
  darkmode: boolean;
  darkmodeSubscription: Subscription;

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.darkmodeSubscription = this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    });  
  }

  ngOnDestroy(){
    this.darkmodeSubscription.unsubscribe();
  }

  onLoadUpdateLayout(){}

}
