<div class="btn-group ml-auto" appDropdown [ngClass]="{'dark': darkmode}">
  <button type="button" class="btn dropdown-toggle" id="dropdownMenuButton"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    Apps
  </button>
  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" >
    <a class="dropdown-item" (click)="setDocTitle('Dashboard')" [routerLink]="['/dashboard']">
      <span class="material-icons-outlined">view_comfy_alt</span>
      Dashboard
    </a>
    <a class="dropdown-item" (click)="setDocTitle('Todo')" routerLink="/todo">
      <span class="material-icons-outlined">list_alt</span>
      TodoApp
    </a>
    <a class="dropdown-item" (click)="setDocTitle('Photos')" [routerLink]="['/photos']">
      <span class="material-icons-outlined">image</span>
      Photos
    </a>
    <a class="dropdown-item" (click)="setDocTitle('TicTok')" [routerLink]="['/tictactoe']">
      <span class="material-icons-outlined">casino</span>
      TicTacToe
    </a>
    <a class="dropdown-item" (click)="setDocTitle('Images')" [routerLink]="['/images']">
      <span class="material-icons-outlined">image</span>
      Images</a>
    <a class="dropdown-item" (click)="setDocTitle('Chat')" [routerLink]="['/chat']">
      <span class="material-icons-outlined">chat</span>
      Chat
    </a>
    <a class="dropdown-item" routerLink="/login"><mat-icon>settings_power</mat-icon>SignOff</a>
  </div>
</div>