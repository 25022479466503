
  <div class="sidenav__title"><span>Projects</span><mat-icon (click)="addProject()">add</mat-icon></div>
  <div class="sidenav__lists">
    <div class="sidenav__list-item" routerLinkActive="active" routerLink="{{project.id}}"  *ngFor="let project of projects">
      <input type="text" [(ngModel)]="project.name" (keyup)="onKeyUponProjectName(project)" placeholder="New project" >
      <div *ngIf="project.isCreator" class="btn-group ml-auto projectOptionMenu" appDropdown>
        <button type="button" class="btn" id="projectDropDownMenu" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          <mat-icon fontSet="material-icons-outlined">more_horiz</mat-icon>
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="projectDropDownMenu">
          <li class="dropdown-item" (click)="deleteProject(project)">Delete</li>
          <li class="dropdown-item">Archive</li>
        </div>
      </div>
    </div>
  </div>


