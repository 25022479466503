<div class="container" >
  <h2 mat-dialog-title>Add a photo</h2>
  <mat-dialog-content class="mat-typography">

  <label>Photo</label>
  <div class="dataItem" [ngClass]="{'dark' : darkmode }">
    <input type="file" #file/>
  </div>
  <label>Title</label>
  <div class="dataItem" [ngClass]="{'dark' : darkmode }">
    <input type="text" name="name" [(ngModel)]="name" placeholder="Photo's title"/>
  </div>

  <label>Description</label>
  <div class="dataItem" [ngClass]="{'dark' : darkmode }">
    <textarea name="description" [(ngModel)]="description" placeholder="Photo's description"></textarea>
  </div>



  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="upload()">Save</button>
  </mat-dialog-actions>
</div>


