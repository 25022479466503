<app-header [brandname]="'Images'">
  <input type="text" value="" class="search" [ngClass]="{'dark': darkmode == true}" placeholder="Search"/>
</app-header>
<div class="layout" appScrollTracker #overlay1>
  <app-masonry-layout>
    <div class="item" *ngFor="let item of items" [ngClass]="{'dark': darkmode == true}">
      <div class="content">
        <img src="{{item.image}}" (load)="onLoadUpdateLayout()" appLazyLoad/>
        <p>{{item.text}}</p>
      </div>
    </div>
  </app-masonry-layout>
</div>

<div class="plus" (click)="showOptions()">
</div>

