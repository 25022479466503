import { SafeUrl } from "@angular/platform-browser";

export class Photo {
    id: number;
    name: string;
    description: string;
    photo_url: string;
    uploaded_at: Date;
    safe_url?: SafeUrl;
    public?: boolean;
}
