import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  date: Date = new Date()



  private darkmodeSource = new BehaviorSubject<boolean>(this.date.getHours() > 18 || this.date.getHours() < 6 ? true : false)
  darkmode = this.darkmodeSource.asObservable();

  constructor() { }
  
  changeDarkMode(status: boolean){
    this.darkmodeSource.next(status);
  }

  scrollbottom = new Subject();
  scrollbottom$ = this.scrollbottom.asObservable();

}


