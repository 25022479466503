import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input('darkmode') darkmode: boolean;
  constructor(private titleService: Title) { }

  ngOnInit() {

  }

  setDocTitle(title: string){
    this.titleService.setTitle(title);
  }

}
