import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TodoAppComponent } from './todo-app/todo-app.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TodoListComponent } from './todo-app/todo-list/todo-list.component';
import { TodoDetailComponent } from './todo-app/todo-detail/todo-detail.component';
import { TodoSideMenuComponent } from './todo-app/todo-side-menu/todo-side-menu.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { MaterialModule } from './material/material.module';
import { DropdownDirective } from './shared/dropdown.directive';
import { InputResizeDirective } from './shared/inputResize.directive'
import { TextAreaAutoGrowDirective } from './shared/textareaAutoGrow/textAreaAutoGrow.directive';
import { NiceDateFormatPipePipe } from './shared/niceDateFormatPipe.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TextareaAutoGrowComponent} from './shared/textareaAutoGrow/textareaAutoGrow.component';
import { InputAutoGrowComponent } from './shared/inputAutoGrow/inputAutoGrow.component';
import { InputAutoGrowDirective } from './shared/inputAutoGrow/inputAutoGrow.directive';
import { ElapseDateFormatPipe } from './shared/elapseDateFormat.pipe';
import { DatepickerComponent } from './shared/datepicker/datepicker.component';
import { LoginComponent } from './login/login.component';
import { AuthService, AuthGuard, AuthInterceptor } from './shared/auth.service';
import { ImagesComponent } from './images/images.component';
import { MasonryLayoutComponent } from './masonry-layout/masonry-layout.component';
import { MenuComponent} from './shared/menu/menu.component'
import { WeatherComponent } from './dashboard/weather/weather.component';
import { NewsComponent } from './dashboard/news/news.component';
import { InitialIconComponent } from './todo-app/initial-icon/initial-icon.component';
import { AssigneeComponent } from './todo-app/assignee/assignee.component';
import { DndDirective } from './todo-app/dnd.directive';
import { LazyLoadDirective } from './shared/lazyload.directive';
import { ScrollTrackerDirective } from './shared/ScrollTracker.directive';
import { OptionsComponent } from './images/options/options.component';
import { StartComponent } from './todo-app/start/start.component';
import { PhotosComponent } from './photos/photos.component';
import { PhotoOptionsComponent } from './photos/photoOptions/photoOptions.component';
import { ChatComponent } from './chat/chat.component';
import { PhotoComponent } from './photos/photo/photo.component';
import { PhotoFilterPipe } from './photos/photoFilter.pipe';
import { PhotoShowComponent } from './photos/photoShow/photoShow.component';
import { PhotoDataComponent } from './photos/photoData/photoData.component';
import { AddPhotoComponent } from './photos/addPhoto/addPhoto.component';
import { NewsFilterPipe } from './dashboard/news/newsFilter.pipe';
import { TictactoeComponent } from './tictactoe/tictactoe.component';

@NgModule({
  declarations: [														
    AppComponent,
      TodoAppComponent,
      HeaderComponent,
      DashboardComponent,
      TodoListComponent,
      TodoDetailComponent,
      TodoSideMenuComponent,
      SideMenuComponent,
      DropdownDirective,
      TextareaAutoGrowComponent,
      InputResizeDirective,
      TextAreaAutoGrowDirective,
      NiceDateFormatPipePipe,
      InputAutoGrowComponent,
      InputAutoGrowDirective,
      ElapseDateFormatPipe,
      DatepickerComponent,
      LoginComponent,
      ImagesComponent,
      MasonryLayoutComponent,
      MenuComponent,
      WeatherComponent,
      NewsComponent,
      InitialIconComponent,
      AssigneeComponent,
      DndDirective,
      LazyLoadDirective,
      ScrollTrackerDirective,
      OptionsComponent,
      StartComponent,
      PhotosComponent,
      PhotoOptionsComponent,
      ChatComponent,
      PhotoComponent,
      PhotoFilterPipe,
      PhotoShowComponent,
      PhotoDataComponent,
      AddPhotoComponent,
      NewsFilterPipe,
      TictactoeComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    CKEditorModule,
    HttpClientModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Title
  ],
  bootstrap: [AppComponent],
  entryComponents: [OptionsComponent, PhotoOptionsComponent, PhotoShowComponent, AddPhotoComponent]
})
export class AppModule { }
