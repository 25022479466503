import { Directive, ElementRef, Renderer2, HostListener, AfterContentChecked } from '@angular/core';

@Directive({
  selector: '[appTextAreaAutoGrow]'
})
export class TextAreaAutoGrowDirective implements AfterContentChecked{

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('keyup') onKeyUp(){
    this.autoGrow();
  }

  ngAfterContentChecked(){
    this.autoGrow();
  }


  autoGrow(){
    //Get the textarea nativeElement and get the sibling div's nativelElement and set the div value = sibling
    const textarea = this.el.nativeElement;
    const div = this.el.nativeElement.parentElement.querySelector('div');
    this.renderer.setProperty(div, 'innerHTML', textarea.value.replace(/\n/g, '<br/>'));


  }
}
