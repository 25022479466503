import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

import { ThemeService } from '../shared/theme.service';
import { Message } from './message';
import { GlobalConstants } from '../shared/global-constants';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

  private myWebSocket: WebSocketSubject<any> 

  @ViewChild('message') message: ElementRef;
  darkmode: boolean;
  darkmodeSubscription: Subscription;
  messages: Message[] = [];

  subscription: Subscription;
  subscriptions: Subscription[] = [];

  

  constructor(private titleService: Title, private themeService: ThemeService, private renderer: Renderer2, private authService: AuthService) { 

    const token = localStorage.getItem('token');
  
    this.myWebSocket = webSocket(GlobalConstants.websocketurl +'ws/chat' + "?token=" + token);

    this.darkmodeSubscription = this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    })
    this.subscriptions.push(this.darkmodeSubscription);

    this.subscription = this.myWebSocket.asObservable().subscribe(    
      msg => //console.log('message received: ' + msg.message)
      // Called whenever there is a message from the server  
      
      {
        this.messages.push({author: msg.author, message: msg.message})
      }
      ,err => console.log(err)
      // Called if WebSocket API signals some kind of error    
      ,() => console.log('complete') 
      // Called when connection is closed (for whatever reason)  
   );
   this.subscriptions.push(this.subscription);
  }

  ngOnInit(){
    this.titleService.setTitle('Chat');
  }

  sendMsg() {
    let message = this.message.nativeElement.value;
    if(message && message != ''){
      this.myWebSocket.next({ author: 'name', message: message});
      this.renderer.setProperty(this.message.nativeElement,'value','');
    }
    this.refreshToken()
  }

  ngOnDestroy(){
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }

  refreshToken() {
    if (this.authService.isLoggedIn)
      this.authService.refreshToken();
  }

}
