import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { ThemeService } from 'src/app/shared/theme.service';
import { Subscription, throwError, of } from 'rxjs';
import { WeatherService } from 'src/app/shared/weather.service';
import { DatePipe } from '@angular/common';
import { catchError, map } from 'rxjs/operators';
import { CountryService } from 'src/app/shared/country.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit, OnDestroy {
  @Input('place') place: string;
  @Output() deleteCityEvent = new EventEmitter();
  @Output() addCityEvent = new EventEmitter<string>();
  @Output() showAddWeather = new EventEmitter<boolean>();
  darkmode: boolean;
  weatherName: string;
  weatherDate: string;
  weatherTemp: number;
  weatherTempHigh: number;
  weatherTempLow: number;
  weatherMain: string;
  weatherIcon: string;
  weatherMain2: string;
  weatherDesc: string;
  weatherClasses: string;
  Country: string;
  nighttime: boolean;
  subscriptions: Subscription[] = [];

  weatherTable = {
    'clear_sky': 'mi-sun',
    'clear_sky dark': 'mi-moon',
    'sky_is_clear': 'mi-sun',
    'clear': 'mi-sun',
    'few_clouds': 'mi-cloud-sun',
    'scattered_clouds': 'mi-cloud-sun',
    'overcast_clouds': 'mi-cloud',
    'broken_clouds': 'mi-cloud',
    'light_rain': 'mi-rain',
    'heavy_rain': 'mi-rain-heavy',
    'drizzle': 'mi-rain',
    'light_thunderstorm': 'mi-thunder',
    'heavy_thunderstorm': 'mi-thunderstorm',
    'light_snow': 'mi-snow',
    'heavy_rainsnow': 'mi-snow-heavy',
    'heavy_snow': 'mi-snow-heavy',
    'mist': 'mi-mist',
    'haze': 'mi-fog',
    'fog': 'mi-fog'
  }

  nightIcon = ['clear_sky']

  forecasts = [];

  constructor(private themeService: ThemeService, private weatherService: WeatherService, private countryService: CountryService) { }

  ngOnInit() {
    this.subscriptions.push(this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    }));  

    if(this.place){

      this.subscriptions.push(this.weatherService.get_weather(this.place) 
      .subscribe(data=>{
        this.weatherName = data.name;

        var date = this.converToCityLocalTime(data.dt, data.timezone);
        var datePipe = new DatePipe("en-US");
        this.weatherDate = datePipe.transform(date, 'MMM d, yyyy h:mm a');
        this.weatherTemp = data.main.temp;
        this.weatherTempHigh = data.main.temp_max;
        this.weatherTempLow = data.main.temp_min;
        this.weatherDesc = data.weather[0].description;
        this.weatherClasses = this.getWeatherClasses(data.weather[0].description);

        this.Country = data.sys.country;

        //Get sunset and sunrise so we can determine when we should set to darkmode for the city.
        var sunrise = this.converToCityLocalTime(data.sys.sunrise, data.timezone);
        var sunset = this.converToCityLocalTime(data.sys.sunset, data.timezone);
        this.nighttime = date > sunset|| date < sunrise ? true : false;

        if(this.nightIcon.includes(this.weatherClasses) && this.nighttime){
          this.weatherIcon = this.weatherTable[this.weatherClasses + ' dark'];
        } else {
          this.weatherIcon = this.weatherTable[this.weatherClasses];
        }

        this.weatherClasses += this.nighttime ? ' dark' : '';
        this.weatherClasses += ' alt' + this.getAltClases(0,3);

      }));


      this.subscriptions.push(this.weatherService.get_forecast(this.place).subscribe(data =>{

        data.list.slice(1).map((d,i) =>{
          var date = new Date(d.dt * 1000);
          var datePipe = new DatePipe("en-US");
          var tempClass = this.getWeatherClasses(d.weather[0].description);    
          this.forecasts.push({day: datePipe.transform(date, 'EEE'), temp_max: d.temp.max, temp_min: d.temp.min, weatherIcon: this.weatherTable[tempClass]});
        });
      }));

    }
    
  }

  getWeatherClasses(description: any): string{
    var classes = ''

    if(description.includes('thunderstorm')){
      classes += 'thunderstorm'
      if(description.includes('light')){
        classes = 'light_thunderstorm'
      } else {
        classes = 'heavy_thunderstorm'
      }
    }

    if(description.includes('rain')){
      classes+= 'rain'

      if(description.includes('light')){
        classes = 'light_rain'
      } else {
        classes = 'heavy_rain'
      }
    }

    if(description.includes('drizzle')){
      classes += 'drizzle'
    }

    if(description.includes('snow')){
      classes += 'snow'

      if(description.includes('light')){
        classes = 'light_snow'
      } else {
        classes = 'heavy_snow'
      }
    }

    if(classes == ""){
      classes = description.replaceAll(' ','_');
    }

    return classes;

  }

  ngOnDestroy(){
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

  converToCityLocalTime(localtime: number, timezone: number): Date{
    var d = new Date(localtime * 1000),
    localTime = d.getTime(),
    localOffset = d.getTimezoneOffset() * 60000,
    utc = localTime + localOffset;
    return new Date(utc+(timezone*1000));
  }

  getAltClases(min, max): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  AddNewCity(city: string){
    city != '' ? this.addCityEvent.emit(city) : '';
    // this.showAddWeather.emit(false);
  }

  onClose(){
    this.deleteCityEvent.emit();
  }

  getCountryName(twoletter: string){
   
    return this.countryService.getCountryNameByTwoDigitCode(twoletter);

  }

  onCloseAdd(){
    this.showAddWeather.emit(false);
  }


}
