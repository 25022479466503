import { Component, OnInit } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error: any;
  returnUrl: string;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.authService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login(username: string, password: string){
    this.authService.login(username.toLowerCase(), password).subscribe(
      success => this.router.navigateByUrl(this.returnUrl),
      error => this.error = error
    );
  }

  logout(){
    this.authService.logout();
  }

  onEnter(){
    
  }

}
