<app-header [brandname]="'TicTacToe'"></app-header>
<div class="tictactoe" [ngClass]="{'dark': darkmode }">
    <div class="grid">
        <div class="t-row">
            <button  class="box" (click)="onClick($event.target)" id="0">
                <span class="text"></span>
            </button>
            <button class="box" (click)="onClick($event.target)" id="1">
                <span class="text"></span>
            </button>
            <button class="box" (click)="onClick($event.target)" id="2">
                <span class="text"></span>
            </button>
        </div>
        <div class="t-row">
            <button class="box" (click)="onClick($event.target)" id="3"></button>
            <button class="box" (click)="onClick($event.target)" id="4"></button>
            <button class="box" (click)="onClick($event.target)" id="5"></button>
        </div>
        <div class="t-row">
            <button class="box" (click)="onClick($event.target)" id="6"></button>
            <button class="box" (click)="onClick($event.target)" id="7"></button>
            <button class="box" (click)="onClick($event.target)" id="8"></button>
        </div>

    </div>
</div>