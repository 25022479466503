import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Task } from './Task';
import { Comment} from './Comment';
import { Subject, Observable, throwError, BehaviorSubject} from 'rxjs';
import { ApiData } from '../shared/ApiData';
import { Project } from './Project';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from '../shared/User';
import { GlobalConstants } from '../shared/global-constants';

@Injectable({
  providedIn: 'root'
})
export class TasksService{
  tasks: Task[];
  users: User[] = [];
  allowedusers: User[] = [];
  projects: Project[];
  project: Project;

  // selectTask = new Subject<Task>();
  selectTask = new Subject<Task>();
  selectTask$ = this.selectTask.asObservable();

  sidenav = new Subject<boolean>();
  sidenav$ = this.sidenav.asObservable();

  selectedProject = new Subject<Project>();
  selectedProject$ = this.selectedProject.asObservable();

  shareUpdated = new Subject<User[]>();
  sharedProject$ = this.shareUpdated.asObservable();

  selectedProjectId = new BehaviorSubject<number>(0);
  selectedProjectId$ = this.selectedProjectId.asObservable();

  isDetailWindowOpened = new Subject<boolean>();
  detailWindowOpened$ = this.isDetailWindowOpened.asObservable();

  addedAttachment= new Subject<boolean>()
  addedAttachment$ = this.addedAttachment.asObservable();

  getComment = new Subject<Task>()
  getComment$ = this.getComment.asObservable();

  api_url = GlobalConstants.apiUrl
 

  constructor(private http: HttpClient, private router: Router) { }

  uploadFile(file: File, taskid: string, projectid: string, mime: string): Observable<boolean>{

    const url = this.api_url + 'api/uploadfile/';
    const formData: FormData = new FormData;
    formData.append('file', file, file.name)
    formData.append('project', projectid)
    formData.append('task', taskid)
    formData.append('name', file.name)
    formData.append('mime', mime)


    return this.http.post<boolean>(url, formData)
      .pipe(
        catchError(this.handleError)
      );
  }

  deletePrivateFile(fileid: number): Observable<File>{
    return this.http.delete<File>(this.api_url + 'api/uploadfile/' + fileid + '/')
    .pipe(
      catchError(this.handleError)
    );
  }

  getAttachments(projectid: number, taskid: number): Observable<File[]>{
    let params = new HttpParams();
    params = params.append('task', taskid.toString());
    params = params.append('project', projectid.toString());
    return this.http.get<File[]>(this.api_url + 'api/uploadfile/', {params: params})
    .pipe(
      catchError(this.handleError)
    );
  }

  downloadAttachments(fileid: number, name: string, download: boolean){

    this.http.get(this.api_url + 'api/uploadfile/' + fileid + '/download/',{responseType: 'blob' as 'json'})
    .pipe(
      catchError(this.handleError)
    )
    .subscribe(
      (response: any) =>{
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));


          downloadLink.setAttribute('target', "_blank_");



            document.body.appendChild(downloadLink);

            if(!download){
              let pwa = window.open(downloadLink.href);
              if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
              alert( 'Please disable your Pop-up blocker and try again.');
              }
            } else {
              downloadLink.setAttribute('download', name);
              downloadLink.click();
            }                          
        })
  
  }


  getUsers(): Observable<User[]>{
    return this.http.get<User[]>(this.api_url + 'api/users/')
    .pipe(
      catchError(this.handleError)
    );
  }

  getComments(taskId: number): Observable<Comment[]>{
    let params = new HttpParams();
    params = params.append('taskId', taskId.toString());
    return this.http.get<Comment[]>(this.api_url + 'api/comments/', {params: params})
    .pipe(
      catchError(this.handleError)
    );
  }

  addComment(id: number, comment: string): Observable<Comment>{
    return this.http.post<Comment>(this.api_url + 'api/comments/', {'task': id.toString(), 'comment': comment, 'type': 'comment'})
    .pipe(
      catchError(this.handleError)
    );
  }

  addSection(order: number, projectid: number): Observable<Task>{
    return this.http.post<Task>(this.api_url + 'api/tasks/', {'parent': null, 'name': "", 'projectid': projectid, 'order': order})
    .pipe(
      catchError(this.handleError)
    );
  }

  deleteSection(task: Task): Observable<Task>{
    return this.http.delete<Task>(this.api_url + 'api/tasks/' + task.id + '/')    
    .pipe(
      catchError(this.handleError)
    );
  }

  addTask(parentid: number, order: number, projectid: number): Observable<Task>{

    return this.http.post<Task>(this.api_url + 'api/tasks/', {'parent': parentid, 'name': "", 'projectid': projectid, 'order': order, 'description': ''})    
    .pipe(
      catchError(this.handleError)
    );
  }

  deleteTask(task: Task): Observable<Task>{
    return this.http.delete<Task>(this.api_url + 'api/tasks/' + task.id + '/').pipe(   
      catchError(this.handleError)
    );
  }

  getProject(): Observable<Project[]>{
    return this.http.get<Project[]>(this.api_url + 'api/projects/')
    .pipe(
      catchError(this.handleError)
    );
  }

  updateProject(project: Project): Observable<Project>{
    return this.http.put<Project>(this.api_url + 'api/projects/' + project.id + '/', project)    
    .pipe(
      catchError(this.handleError)
    );
  }

  createProject(): Observable<Project>{
    return this.http.post<Project>(this.api_url + 'api/projects/', {name: "", completed: false})    
    .pipe(
      catchError(this.handleError)
    );
  }

  deleteProject(id: number): Observable<string>{
    return this.http.delete<string>(this.api_url + 'api/projects/' + id + '/')    
    .pipe(
      catchError(this.handleError)
    );
  }
  
  getTasks(projectid: number): Observable<Task[]>{
    let params = new HttpParams();
    params = params.append('projectId', projectid.toString());


    return this.http.get<Task[]>(this.api_url + 'api/tasks/', {params: params})
    .pipe(
      catchError(this.handleError)
    );

  }

  getSubTasks(task: Task): Observable<Task[]>{
    let params = new HttpParams();
    params = params.append('parent', task.id.toString());

    return this.http.get<Task[]>(this.api_url + 'api/tasks/', {params: params})
    .pipe(
      catchError(this.handleError)
    );
  }

  updateTask(task: Task): Observable<Task>{

    task = {...task, 'name': task.name.substring(0,4000), 'description': encodeURIComponent(task.description)}
    return this.http.put<Task>(this.api_url + 'api/tasks/' + task.id + '/', task)
    .pipe(
      catchError(this.handleError)
    );
  }


  private handleError = (error: HttpErrorResponse)=> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
        if (error.status === 401 || error.status === 500){
          this.router.navigate(['login']);
        }

    }
    // Return an observable with a user-facing error message.
    //this.router.navigate(['login']);
    return throwError(
      'Something bad happened; please try again later.');
  }

}
