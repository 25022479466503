import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { User } from 'src/app/shared/User';

@Component({
  selector: 'app-initial-icon',
  templateUrl: './initial-icon.component.html',
  styleUrls: ['./initial-icon.component.scss']
})
export class InitialIconComponent implements OnInit, OnChanges {
  @Input('user') user: User;
  @Input('size') size: string;
  initial: string;
  number: number;
  classcolor: string;
  fontcolor: string;


  table = {
    A : 1,
    a : 1,
    B : 2,
    b : 2,
    C : 3,
    c : 3,
    D : 4,
    E : 5,
    F : 6,
    G : 7,
    H : 8,
    I : 9,
    J : 10,
    K : 11, 
    L : 12, 
    M : 13, 
    N : 14, 
    O : 15, 
    P : 16, 
    Q : 17, 
    R : 18,
    S : 19,
    T : 20,
    U : 21,
    V : 22,
    W : 23,
    X : 24,
    Y : 25,
    Z : 26
  }



  constructor() { }

  ngOnInit() {

  }


  ngOnChanges(){
    this.update();

  }


  update(){
    if (this.user){
      this.initial = this.user.first_name[0] + this.user.last_name[0];
      this.number = Math.ceil((this.table[this.user.first_name[0]] + this.table[this.user.last_name[0]]) / 2);
    


      this.classcolor = this.stringToColour(this.user.first_name)

      // if(this.number < 3)
      //   this.classcolor = '#f58231'; 
      // else if(this.number < 5)
      //   this.classcolor = '#e6194B';
      // else if (this.number < 7)
      //   this.classcolor = '#3cb44b';
      // else if (this.number < 9)
      //   this.classcolor = '#3b1566';
      // else if (this.number < 11)
      //   this.classcolor = '#4363d8';
      // else if (this.number < 13)
      //   this.classcolor = '#800000';
      // else if (this.number < 15)
      //   this.classcolor = '#9A6324';
      // else if (this.number < 17)
      //   this.classcolor = '#469990';
      // else if (this.number < 19)
      //   this.classcolor = '#202c61';
      // else if (this.number < 21)
      //   this.classcolor = '#911eb4';
      // else if (this.number < 23)
      //   this.classcolor = '#a9a9a9';
      // else if (this.number < 26)
      //   this.classcolor = '#000000';
      // else
      //   this.classcolor = '#0003';

      
      this.fontcolor = '#FAFAFA'
    } else {
      this.classcolor = 'transparent';
      this.fontcolor = 'transparent';
    }


  }

  stringToColour(str: string){
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }
  
  


}
