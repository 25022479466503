import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, throwError } from 'rxjs';
import { Photo } from './photo';
import { catchError } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalConstants } from '../shared/global-constants';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  NewPhoto = new Subject<Photo>();
  NewPhoto$ = this.NewPhoto.asObservable();

  image: any;
  api_url = GlobalConstants.apiUrl
  url = this.api_url + 'api/photo/';

  constructor(private http: HttpClient, private router: Router, private sanitizer: DomSanitizer) { }


  uploadImage(image: File, name: string = '', description: string = ''): Observable<Photo>{
    const formData: FormData = new FormData;
    formData.append('photo', image, image.name)
    formData.append('name', name)
    formData.append('description', description)
    formData.append('mime', image.type)

    return this.http.post<Photo>(this.url, formData)
    .pipe(
      catchError(this.handleError)
    );

  }

  deletePhoto(photo: Photo): Observable<Photo>{
    return this.http.delete<Photo>(this.url + photo.id + '/')
    .pipe(
      catchError(this.handleError)
    );
  }

  updatePhoto(photo: Photo): Observable<Photo>{
    return this.http.patch<Photo>(this.api_url + 'api/photoNoImage/' + photo.id + '/', {'name': photo.name, 'description': photo.description, 'public': photo.public}) 
    .pipe(
      catchError(this.handleError)
    );
  }

  getPhotos(url: string): Observable<Photo[]>{
    return this.http.get<Photo[]>(url)
    .pipe(
      catchError(this.handleError)
    );
  }

  getPhoto(id: number): Observable<Photo>{
    return this.http.get<Photo>(this.url + id + '/')
    .pipe(
      catchError(this.handleError)
    );
  }

  serveThumbnailPhoto(id: number): Observable<Blob> {
    return this.http.get<Blob>(this.url + id + '/thumbnail/',{responseType: 'blob' as 'json'})
    .pipe(
      catchError(this.handleError)
    );
  }

  servePhoto(id: number): Observable<Blob> {
    return this.http.get<Blob>(this.url + id + '/download/',{responseType: 'blob' as 'json'})
    .pipe(
      catchError(this.handleError)
    );
  }


  private handleError = (error: HttpErrorResponse)=> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
        if (error.status === 401 || error.status === 500){
          this.router.navigate(['login']);
        }
  
    }
    // Return an observable with a user-facing error message.
    //this.router.navigate(['login']);
    return throwError(
      'Something bad happened; please try again later.');
  }


}


