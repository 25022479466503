import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  currentRoute: string;

  constructor(private router: Router) { 
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(( event: NavigationEnd) => {

      this.currentRoute = event.url;
    })
  }

  ngOnInit() {

  }

}
