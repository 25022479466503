import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild, Renderer2, AfterViewChecked } from '@angular/core';
import { Task } from '../Task';
import { Comment } from '../Comment';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDrag } from '@angular/cdk/drag-drop';
import { TasksService } from '../tasks.service';
import * as ClassicEditor from '@collinchen11/ckeditor5-build-classic-with-fontfamily-alignment';
import * as InlineEditor from '@collinchen11/ckeditor5-build-balloon-block-fontfamily-alignment';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { User } from 'src/app/shared/User';
import { UploadAdapter } from '../../shared/UploadAdapter'
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/auth.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-todo-detail',
  templateUrl: './todo-detail.component.html',
  styleUrls: ['./todo-detail.component.scss'],
})
export class TodoDetailComponent implements OnInit, OnDestroy, AfterViewChecked {
  selectedTask: Task;
  parentTask: Task;
  @Input() tasks: Task[];
  @ViewChild('comment') comment: ElementRef;
  @ViewChild('scrollcontent') scrollcontent: ElementRef;
  focus: boolean = false;
  selectedSubTask: Task;
  connectedList = [];
  commentFocus: boolean = false;
  comments: Comment[];
  textareaFocusTries: number = 0;
  scrollToTop: number = 0;
  users: User[];
  attachments: File[];

  subjectUpdateTask: Subject<Task> = new Subject();

  subscription: Subscription;
  subscription2: Subscription;
  subscriptions: Subscription[] = [];
  foundTask: boolean;
  showLogs: boolean = false;
  projectid: number;

  public editor = ClassicEditor;
  public ckconfig = {};
  files: any[] = [];

  currentUser: User;

  constructor(private tasksService: TasksService, private renderer: Renderer2, private http: HttpClient, private authService: AuthService,
      private route: ActivatedRoute, private router: Router
    ) {}

  public onReady(editor) {

    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement(),
    );

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapter(loader, this.selectedTask.projectid, this.selectedTask.id);
    };
  }


  onFileDropped(files: Array < any > ) {
    this.refreshToken();
    for (const item of files) {
      this.tasksService.uploadFile(item, this.selectedTask.id.toString(), this.selectedTask.projectid.toString(), item.type).subscribe(result => {
        this.tasksService.addedAttachment.next(true);
      });

    }

  }

  onDescriptionChange({
    editor
  }: ChangeEvent) {

    const data = editor.getData();

    this.subjectUpdateTask.next(this.selectedTask);

  }


  ngOnInit() {
    this.tasks = this.tasksService.tasks;

    this.subscription = this.authService.getCurrent().subscribe(result => {
      this.currentUser = this.authService.currentUser;
    })
    this.subscriptions.push(this.subscription);


    this.users = this.tasksService.allowedusers;

    this.tasksService.sharedProject$.subscribe(s => {
      this.users = s;
    })  

    this.subscription = this.tasksService.addedAttachment$.subscribe(status => {
      this.subscription = this.tasksService.getAttachments(this.selectedTask.projectid, this.selectedTask.id).subscribe(files => {

        this.attachments = files;
        files.map(file => {
          file['mime'] = file['mime'] !== null ? file['mime'].substring(file['mime'].indexOf('/') + 1, file['mime'].length) : "";
          if (file['mime'] === "vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            file['mime'] = "xlsx"
          if (file['mime'] === "vnd.openxmlformats-officedocument.wordprocessingml.document")
            file['mime'] = "doc"

        })
      });
      this.subscriptions.push(this.subscription);

    })
    this.subscriptions.push(this.subscription);

    //if selected Task changed
    this.subscription = this.tasksService.selectTask$.subscribe(task => {

      try{
        task['description'] = decodeURIComponent(task['description'])
      } catch (e){}

      this.selectedTask = task;

      this.subscription = this.tasksService.getComments(task.id).subscribe(result => {
        this.comments = result;
      })
      this.subscriptions.push(this.subscription);

      this.getParentTask(this.tasks, task.parent);

      this.tasksService.addedAttachment.next(true);
      this.scrollToTop = 0;
    });
    this.subscriptions.push(this.subscription);


    //if project changed
    this.subscription = this.tasksService.selectedProject$.subscribe(project => {
      this.selectedTask = null;
      this.tasksService.isDetailWindowOpened.next(false);
    });
    this.subscriptions.push(this.subscription);

    //task is updated
    this.subscription = this.subjectUpdateTask.pipe(debounceTime(700)).subscribe(task => {
      this.updateTask(task);

    });
    this.subscriptions.push(this.subscription);

    this.tasksService.selectedProjectId$.subscribe(projectid => {
      this.projectid = projectid
    }
      

      
    );


    this.route.params.subscribe(params => {
      this.projectid = +params['projectid']
      if(params['taskid']){
        this.projectid = +params['projectid']
        this.selectedTask = null;
        if(!this.tasks){
          this.subscription = this.tasksService.selectedProject$.subscribe(project => {
            this.subscription = this.tasksService.getTasks(project.id).subscribe(data => {
              this.tasksService.tasks = data;
              this.tasks = this.tasksService.tasks;
              this.projectid = project.id;

              this.setTaskByUrl(+params['taskid']);

            });
            this.subscriptions.push(this.subscription); 
          });
          this.subscriptions.push(this.subscription);

        } else {
          this.setTaskByUrl(+params['taskid']);
        }       
      }
    });

  }

  setTaskByUrl(id: number){
    this.tasksService.isDetailWindowOpened.next(true);

    this.tasks.map(task => {
      task.subtask.map(stask => {
        if (stask.id === id){         
          this.tasksService.selectTask.next(stask);
          this.selectedTask = stask;
          this.getComments(stask);
        }
      })
    });

    if(!this.selectedTask){
      this.router.navigate(['../'], {relativeTo: this.route});
    }
  }

  getComments(task: Task){
    this.subscription = this.tasksService.getComments(task.id).subscribe(result => {
      this.comments = result;
    })
    this.subscriptions.push(this.subscription);
  }

  onFileClick(fileId: number, name: string, download: boolean) {
    this.refreshToken();
    this.tasksService.downloadAttachments(fileId, name, download);
  }

  onDeletePrivateFile(fileId: number, name: string) {
    let permission: boolean = confirm("Are you sure you want to remove this attachment \"" + name + "\"?")
    if (permission) {
      this.refreshToken();
      this.tasksService.deletePrivateFile(fileId).subscribe(result => {
        this.tasksService.addedAttachment.next(true);
      })
    }

  }


  onUpdateAssignee(user, task) {

    if (user !== null) {
      this.selectedTask.user = user.id
    } else {
      this.selectedTask.user = null
    }

    this.updateTask(this.selectedTask);

  }

  addSubtask(parent: number, count: number) {
    this.tasksService.selectedProjectId$.subscribe(projectid => {
      this.projectid = projectid

    })

    this.tasksService.addTask(parent, count, this.projectid).subscribe(result => {
      this.appendTask(this.tasks, result);
      this.refreshToken();
    });


  }

  deleteTask(task: Task) {

    let permission: boolean = confirm("Deleting this task will also delete all subtasks. Are you sure?")

    if (permission)
      this.tasksService.deleteTask(task).subscribe(result => {
        if (this.parentTask.parent !== null) {
          // this.selectedTask = this.parentTask;
          this.tasksService.selectTask.next(this.parentTask);
          this.getParentTask(this.tasks, this.selectedTask.parent);
        } else {
          this.closeDetailWindow();
        }
        this.popTask(this.tasks, task.id);
        this.refreshToken();
      });

  }

  popTask(tasks: Task[], id: number) {
    tasks.map((task, index) => {
      if (task.id === id) {
        tasks.splice(index, 1)
      } else if (task.subtask && task.subtask.length > 0) {
        this.popTask(task.subtask, id)
      }
    });
  }

  appendTask(tasks: Task[], ntask: Task) {
    tasks.map(task => {
      if (task.id === ntask.parent)
        task.subtask.push(ntask);

      else if (task.subtask && task.subtask.length > 0) {
        this.appendTask(task.subtask, ntask);
      }

    })
  }

  getParentTask(tasks: Task[], id: number) {
    if(tasks)
      tasks.map(task => {
        if (task.id === id) {
          this.parentTask = task
        } else if (task.subtask && task.subtask.length > 0) {
          this.getParentTask(task.subtask, id);
        }
      });

  }

  ngAfterViewChecked() {
    if (this.commentFocus && this.textareaFocusTries < 1) {
      this.renderer.setProperty(this.scrollcontent.nativeElement, 'scrollTop', this.scrollcontent.nativeElement.scrollHeight);
      this.textareaFocusTries++;
    } 

    //This is to auto scroll to the top whenever a new task is selected
    if(this.scrollcontent && this.scrollToTop < 1){
      this.renderer.setProperty(this.scrollcontent.nativeElement, 'scrollTop', 0);
      this.scrollToTop++;
    }
  } 

  addComment(e: Event) {
    e.preventDefault();
    if (this.comment.nativeElement.value == '')
      return;

    let task = this.selectedTask.id;
    let comment = this.comment.nativeElement.value;

    this.subscription = this.tasksService.addComment(task, comment).subscribe(result => {
      this.comments.push(result);
      this.textareaFocusTries = 0;
      this.comment.nativeElement.value = '';
      this.refreshToken();
    });
    this.subscriptions.push(this.subscription);

  }

  updateTask(task: Task) {
    this.subscription = this.tasksService.updateTask(task).subscribe(status => {
      this.refreshToken();
      this.getComments(this.selectedTask);
      this.tasksService.getComment.next(this.selectedTask);
    });
    this.subscriptions.push(this.subscription);
  }

  updateTaskName(value: string) {
    this.selectedTask.name = value;
    this.subjectUpdateTask.next(this.selectedTask);

  }

  updateSubTaskName(task: Task) {
    this.subjectUpdateTask.next(task);
  }

  toggleCompleted() {
    this.refreshToken();
    this.selectedTask.completed = !this.selectedTask.completed;
    this.subjectUpdateTask.next(this.selectedTask);
  }

  UpdateDueDate() {
    this.refreshToken();
    this.subjectUpdateTask.next(this.selectedTask);
  }

  commentFocusIn() {
    this.refreshToken();
    this.commentFocus = true;
    this.textareaFocusTries = 0;
  }

  commentFocusOut() {
    this.commentFocus = false;

  }

  ngOnDestroy() {
    this.subscriptions.map(subscription => {
      if (subscription)
        subscription.unsubscribe();
    })

    this.tasksService.isDetailWindowOpened.next(false);
  }

  closeDetailWindow() {
    this.refreshToken();
    this.tasksService.isDetailWindowOpened.next(false);
    this.router.navigate(['../'], {relativeTo: this.route});
  }

  focusIn(task: Task) {
    this.refreshToken();
    this.selectedSubTask = task;
    this.focus = true;
  }

  focusOut() {
    this.focus = false;
    this.selectedSubTask = null;
  }

  changeSelectTask(task: Task) {
    this.refreshToken();
    this.tasksService.selectTask.next(task);
  }




  dropItem(event: CdkDragDrop < string[] > ) {
    this.refreshToken();
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

      event.container.data.forEach((task, index) => {
        task['order'] = index;
      });

      this.selectedTask.subtask.map(task => {
        this.updateTask(task);
      })

    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);

      event.container.data.forEach((task, index) => {
        task['order'] = index;
        task['parent'] = +event.container.id;
      });

      event.previousContainer.data.forEach((task, index) => {
        task['order'] = index;
        task['parent'] = +event.previousContainer.id;

      });

      this.selectedTask.subtask.map((task, index) => {
        this.updateTask(task);
      })
    }
  }

  getConnectedList(): string[] {
    let list = this.tasks.map(x => `${x.id}`);

    if (this.selectedTask) {
      list.push(this.selectedTask.id.toString());
    }

    this.connectedList = list;
    return list;
  }

  samePredicate(id: number) {

    //prevent the same task from going into it self's sub-task
    return function (item: CdkDrag < number > ) {
      if (item.data['id'] == id) {
        return false;
      }
      return true;
    }

  }

  refreshToken() {
    if (this.authService.isLoggedIn)
      this.authService.refreshToken();
  }
}
