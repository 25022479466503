<div class="photoData-container">
    <header>
      <h2 *ngIf="photo.name">{{photo.name | titlecase}}</h2>
      <div class="menu" *ngIf="isLoggedIn">
        <div class="btn-group ml-auto" appDropdown [ngClass]="{'dark': darkmode}">
          <button type="button" class="btn dropdown-toggle" id="dropdownMenuButton1"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1" >
            <a class="dropdown-item" (click)="showOptions(photo)">
              <mat-icon >edit_note</mat-icon>
              <span>Edit</span>
            </a>
            <a class="dropdown-item" (click)="delete(photo)">
              <mat-icon >delete</mat-icon>
              <span>Remove</span>
            </a>
          </div>
        </div>
      </div>
    </header>

    <p *ngIf="photo.description">{{photo.description}}</p>
</div>




