<app-header [brandname]="'Dashboard'">
   <input type="text" #search [(ngModel)]="inputTerm" class="search" [ngClass]="{'dark': darkmode == true}" placeholder="Search news"/>
</app-header>

<div class="dashboard" [ngClass]="{'dark': darkmode }">
   <div class="section addWeather">
      <h3>Weather <mat-icon *ngIf="!showAddWeatherStatus" (click)="showAddWeather()" title="show add weather box" fontSet="material-icons-outlined">add</mat-icon></h3>
   </div>
   <div class="grid-regular weather-grid">
      <app-weather *ngFor="let c of cities" [place]="c.city" (deleteCityEvent)="onDeleteCity(c.id)"></app-weather>
      <app-weather *ngIf="showAddWeatherStatus" (addCityEvent)="addCity($event)" (showAddWeather)="showAddWeather(false)"></app-weather>
   </div>
   <div class="section">
      <h3>News</h3>
   </div>
   <app-news [items]="news" [inputTerm]="searchTerm"></app-news>
</div> 




