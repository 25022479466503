import { OnInit, Component, OnDestroy } from '@angular/core';
import { Task } from './Task';
import { TasksService } from './tasks.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { User } from '../shared/User';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-todo-app',
  templateUrl: './todo-app.component.html',
  styleUrls: ['./todo-app.component.scss']
})

export class TodoAppComponent implements OnInit, OnDestroy {

  tasks: Task[] = []
  selectedItem: Task;
  showDetail = false;

  subscription: Subscription;
  subscription2: Subscription;
  subscriptions: Subscription[] = []

  constructor(private tasksService: TasksService, private authService: AuthService, private titleService: Title) { }

  showMenu: boolean = false;
  title = 'todosapp';
  currentUser: User;

  onMenuClick() {  
    this.showMenu = !this.showMenu; 
  }
  
  ngOnInit() {

    this.titleService.setTitle('Todo');

    //Have here to so that we don't show the Add Section unless a project is selected.
    this.tasks = this.tasksService.tasks;

    this.subscription = this.tasksService.detailWindowOpened$.subscribe(status=>{
      this.showDetail = status;
    });

    this.subscriptions.push(this.subscription);

    this.subscription = this.tasksService.selectTask$.subscribe(task => {
      this.refreshToken();
      this.selectedItem = task;
      this.subscription2 = this.tasksService.getSubTasks(task).subscribe(data=>{
        this.selectedItem.subtask = data;
      });
      this.subscriptions.push(this.subscription2);
      // this.router.navigate([], {relativeTo: this.route, queryParams: {taskid: task.id}});
    });
    this.subscriptions.push(this.subscription);

    this.subscriptions.push(
      this.tasksService.sidenav$.subscribe(_ => {
        this.showMenu = false;
      })
    )
  }

  ngOnDestroy(){
    this.subscriptions.map(subscription =>{
      if(subscription)
        subscription.unsubscribe();
    })
  }

  refreshToken(){
    if(this.authService.isLoggedIn)
      this.authService.refreshToken();
  }
  
 
}
