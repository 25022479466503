

<div id="login_form">
    <h2>Sign-in</h2>
    
    <form (submit)="login(username.value, password.value)">
        <mat-form-field>
            <mat-label>Username</mat-label>
            <input #username matInput autocomplete="off" placeholder="jdoe">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input #password matInput type="password">
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="login(username.value, password.value)">Sign-in</button>
    </form><br>
    <p>{{ error?.message }}</p>
    <p *ngIf="error">{{ error?.error | json }}</p>
</div>
