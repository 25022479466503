import { Component, enableProdMode } from '@angular/core';
import { LoaderService } from './shared/loader.service';

enableProdMode()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(public loadingService: LoaderService){}
}
