<div class="grid-container">
  <div class="menu-icon" (click)="onMenuClick()">
    <i class="fas fa-bars header__menu"></i>
  </div>
  <app-header class="header" [brandname]="''"></app-header>
  <aside class="sidenav" [ngClass]="{'active' : showMenu}">
    <div class="sidenav__logo"></div>

    <div class="sidenav__close-icon" (click)="onMenuClick()">
      <i class="fas fa-times sidenav__brand-close"></i>
    </div>

    <ul class="sidenav__list">
      <app-todo-side-menu></app-todo-side-menu>
    </ul>
  </aside>
  <main class="main">
    <div class="todo" cdkDropListGroup>
      <router-outlet></router-outlet>
    </div>

  </main>
  <!-- <footer class="footer">
        <div class="footer__copyright">&copy; 2018 MTH</div>
        <div class="footer__signature">Made with love by pure genius</div>
    </footer> -->
</div> 