<section class="weathers" [ngClass]="{'dark': darkmode, 'addWeather': !place}">

  <div class="panel weather-panel" [ngClass]="weatherClasses" *ngIf="place">

    <div class="close">
      <mat-icon fontSet="material-icons-outlined" (click)="onClose()">close</mat-icon>
    </div> 
    <section class="today">
      <div class="name" >
        <h4> <span [ngClass]="{'h5': weatherName?.length > 11 }" title="{{ weatherName}}">{{weatherName }}</span>&nbsp;<small class="h6" title="{{getCountryName(Country)}}">{{Country}}</small><br><small>{{ weatherDate }}</small></h4>

        <p [ngClass]="{'h5': weatherDesc?.length < 11, 'h6': weatherDesc?.length > 10}" title="{{weatherDesc}}"><i class="mi mi-fw mi-lg " [ngClass]="weatherIcon"></i> {{weatherDesc | slice:0:15}}</p>
      </div>
      <div class="h3 temperature">
        <span>{{weatherTemp | number: '1.0-0'}}°<br></span>
        <small>{{weatherTempLow | number: '1.0-0'}}° / {{weatherTempHigh | number: '1.0-0'}}°</small>
      </div>
    </section>
    <section class="forecast">
      <ul class="list-inline">
        <li class="text-center" *ngFor="let forecast of forecasts">
          <h3 class="h5">{{ forecast.day}}</h3>
          <p><i class="mi mi-fw mi-2x" [ngClass]="forecast.weatherIcon"></i><br>{{forecast.temp_min | number: '1.0-0'}}°/{{forecast.temp_max | number: '1.0-0'}}°</p>
        </li>
      </ul>
    </section> 
  </div> 
  <div class="weather-panel addWeather" *ngIf="!place">
    <div class="close">
      <mat-icon fontSet="material-icons-outlined" (click)="onCloseAdd()">close</mat-icon>
    </div> 
    <label>Add a city</label>
    <form class="addCityForm">
      <input #newcityname matInput autocomplete="off" placeholder="ex. new york, us">
      <button mat-flat-button (click)="AddNewCity(newcityname.value); newcityname.value = ''">Add</button>
    </form>
  </div>
</section>  
