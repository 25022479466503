import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-inputAutoGrow',
  templateUrl: './inputAutoGrow.component.html',
  styleUrls: ['./inputAutoGrow.component.scss']
})
export class InputAutoGrowComponent implements OnInit {
  @Input('inBoundData') inBoundData: string;
  @Output() outBoundData = new EventEmitter<string>();
  @Output() focusIn = new EventEmitter<any>();
  @Output() focusOut = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onUpdate(){
    this.outBoundData.emit(this.inBoundData);
  }

  focusin(){
    this.focusIn.emit();
  }

  focusout(){
    this.focusOut.emit();
  }

}
