import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'elapseDateFormat'
})
export class ElapseDateFormatPipe implements PipeTransform {

  transform(value: string) {

    var datePipe = new DatePipe("en-US");
    var current_year_format_val = datePipe.transform(value, 'MMM d');
    var year_date_format_val = datePipe.transform(value, 'MMM d, yyyy');
    var date = new Date(value),
    today = new Date(),
    diff = ((today.getTime() - date.getTime()) / 1000),
    minDiff = Math.floor(diff/60),
    hourDiff = Math.floor(diff/(60*60)),
    dayDiff = Math.floor(diff/(60*60*24));

    if(isNaN(diff))
      return '';

    return minDiff < 2 && "Just now" ||
    minDiff < 50 && minDiff + " minutes ago " ||
    minDiff < 66 && "an hour ago " ||
    hourDiff < 25 && hourDiff + " hours ago " || 
    dayDiff == 1 && dayDiff + " day ago" ||
    dayDiff < 5 && dayDiff + " days ago" ||
    today.getFullYear() === date.getFullYear() && current_year_format_val ||
    year_date_format_val;

  


 }

}





