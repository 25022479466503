import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { queue } from 'rxjs/internal/scheduler/queue';
import { Weather } from '../dashboard/weather/weather';
import { GlobalConstants } from '../shared/global-constants';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {


  api_url = GlobalConstants.apiUrl
  constructor(private http: HttpClient) { }

  get_weather(q: string): Observable<any>{
    return this.http.get<any>(this.api_url + 'weather/' + q);
  }

  get_forecast(q: string): Observable<any>{
    return this.http.get<any>(this.api_url + 'forecast/' + q);
  }

  get_my_cities():Observable<Weather[]>{
    return this.http.get<Weather[]>(this.api_url + 'api/weather/');

  }

  removeCity(id: number):Observable<any>{
    return this.http.delete<any>(this.api_url + 'api/weather/' + id + '/');
  }

  addCity(city: string):Observable<any>{
    const headers = { 'content-type': 'application/json'}  
    return this.http.post<any>(this.api_url + 'api/weather/',{city: city},{headers: headers} );
  }

} 
