import { Directive, HostListener, HostBinding, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDropdown]'
})
export class DropdownDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostBinding('class.show') isOpen = false;
  @HostBinding('class.dropup') isBottom = false;
  
  @HostListener('click') toggleOpen(){
    this.isOpen = !this.isOpen;
    let part = this.el.nativeElement.querySelector('.dropdown-menu')

    //determine if dropup class is needed if the dropdown is close to the bottom of the window screen.
    let position = this.el.nativeElement.getBoundingClientRect().y
    let windowHeight = window.innerHeight;

    if(position + 120 < windowHeight){
      this.isBottom = false;
    } else{
      this.isBottom = true;
    }

    if(this.isOpen){
      this.renderer.addClass(part,'show');
    }else{
      this.renderer.removeClass(part,'show');

    }
    

  };

  @HostListener('document:click', ['$event', '$event.target']) 
  public closeDD(event: MouseEvent, targetElement: HTMLElement): void{
    if (!targetElement) {
      return;
    }

    const clickedInside = this.el.nativeElement.contains(targetElement);
    //if not clicked inside
    if (!clickedInside) {
      this.isOpen = false;
      let part = this.el.nativeElement.querySelector('.dropdown-menu');
      this.renderer.removeClass(part,'show');
    }
  }
  
}
