import { Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { ThemeService } from '../shared/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy{
  @Input('brandname') brandname: string = "";  
  darkmode: boolean;
  darkmodeSubscription: Subscription;


  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.darkmodeSubscription = this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    });
  }

  onCheckBackgroundColor(event){
    this.themeService.changeDarkMode(!this.darkmode);
  }

  ngOnDestroy(){
    this.darkmodeSubscription.unsubscribe();
  }

}
