import { Pipe, PipeTransform } from '@angular/core';
import { News } from './News';

@Pipe({
  name: 'newsFilter'
})
export class NewsFilterPipe implements PipeTransform {

  transform(news: News[], search: string): any {
    if (!news || !search || search.length < 3)
      return news;

    let terms = search.toLowerCase().trim().split(" ")
    console.log(terms)

    return news.filter(new1 => {
      //return (terms.every(t => new1.title.toLowerCase().split(" ").includes(t))) || (terms.every(t => new1.description.toLowerCase().split(" ").includes(t)))
      return (terms.some(t => new1.title && new1.title.toLowerCase().indexOf(t) !== -1)) || (terms.some(t => new1.description && new1.description.toLowerCase().indexOf(t) !== -1))
    });
  }

}
