export class GlobalConstants {
    //public static apiUrl: string = "https://www.collinchen.com/"
    //public static websocketurl: string = "wss://www.collinchen.com/";

    public static port: string = window.location.protocol === 'https:' ? '' : ':8000';
    public static apiUrl: string = window.location.protocol + "//" + window.location.hostname + GlobalConstants.port + '/'
    public static wsprotocol: string = window.location.protocol === "https:" ? "wss" : "ws";
    public static websocketurl: string = GlobalConstants.wsprotocol + "://" + window.location.hostname + GlobalConstants.port + '/'

}
