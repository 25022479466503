  <div class="container" >
    <h2 mat-dialog-title>Edit - {{ photo.name | slice:0:80}}</h2>
    <mat-dialog-content class="mat-typography">
    
      <label>Title</label>
      <div class="dataItem" [ngClass]="{'dark' : darkmode }"><input type="text" [(ngModel)]="photo.name" placeholder="Photo's title"/></div>
      <label>Description</label>
      <div class="dataItem" [ngClass]="{'dark' : darkmode }">
        <app-textareaAutoGrow [inBoundData]="photo.description" (outBoundData)="onUpdateDescription($event)" [darkOk]="true"></app-textareaAutoGrow>
      </div>
      <input type="checkbox" [(ngModel)]="photo.public" /> Make it public

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button [mat-dialog-close]="photo" cdkFocusInitial>Save</button>
    </mat-dialog-actions>
  </div>


