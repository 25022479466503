import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Photo } from '../photo';
import { PhotoService } from '../photo.service';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  @Input() photo: Photo;

  src;
  
  constructor(private photoService: PhotoService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getPhotoUrl(this.photo.id);
  }

  getPhotoUrl(id: number): any{
    this.subscriptions.push(this.photoService.serveThumbnailPhoto(id).subscribe(response => {
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      this.src = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(new Blob(binaryData, {type: dataType})));     
    }));
  }

  onLoadUpdateLayout(){}

  ngOnDestroy(){
    this.subscriptions.map(sub => {
      sub.unsubscribe();
    })
  }

}
