import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { ThemeService } from '../shared/theme.service';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import { GlobalConstants } from '../shared/global-constants';
import { Message } from './tictactoeMessage';

@Component({
  selector: 'app-tictactoe',
  templateUrl: './tictactoe.component.html',
  styleUrls: ['./tictactoe.component.scss']
})
export class TictactoeComponent implements OnInit {
  darkmode: boolean;
  subscription: Subscription;
  subscriptions: Subscription[] = [];
  private myWebSocket: WebSocketSubject<any> 
  messages: Message[] = [];
  myTurn = true;
  game = true;
  grid = 
  [-1,-1,-1,
    -1,-1,-1,
    -1,-1,-1
  ]

  constructor(private titleService: Title, private themeService: ThemeService, private authService: AuthService) { 
    const token = localStorage.getItem('token');
    this.myWebSocket = webSocket(GlobalConstants.websocketurl +'ws/tictactoe' + "?token=" + token);

    this.subscription = this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    })
    this.subscriptions.push(this.subscription)

    this.subscription = this.myWebSocket.asObservable().subscribe(    
      msg => //console.log('message received: ' + msg.message)
      // Called whenever there is a message from the server  
      
      {
        if (msg['action'] =='update_grid')
           this.UpdateGrid(msg)
        else 
          this.resetgrid()
      }
      ,err => console.log(err)
      // Called if WebSocket API signals some kind of error    
      ,() => console.log('complete') 
      // Called when connection is closed (for whatever reason)  
   );
   this.subscriptions.push(this.subscription);
  }

  resetgrid(){
    const elements = document.getElementsByClassName("box");
    for (let i = 0; i < elements.length; i++){
      elements[i].innerHTML = '';
      this.grid[i] = -1
      elements[i].classList.remove("disabled")  
      elements[i].removeAttribute('disabled')
    }
    this.game = true;
 
  }

  UpdateGrid(msg){
    if (msg['action'] =='update_grid'){
      let el = document.getElementById(msg['grid']);
      this.grid[msg['grid']] = 0
      el.innerHTML = 'O'
      el.classList.add("disabled")  
      el.setAttribute('disabled','')
      this.myTurn = true;
      let result = this.check()
      if(result["status"] == 1){
        if(result['whowon'] == 1)
          alert("You won!")
        else
          alert("You lost!")

        this.resetgrid()
      }

      if (!this.grid.includes(-1)){
        this.resetgrid()
      }
    }


  }

  check(){
    if ((this.grid[0] == this.grid[1]) && (this.grid[1] == this.grid[2]) && this.grid[0] != -1){
      if (this.grid[0] == 1)
        return {"status": 1, "whowon": 1};
      else 
        return {"status": 1, "whowon": 0};
    }

    if ((this.grid[3] == this.grid[4]) && (this.grid[4] == this.grid[5]) && this.grid[3] != -1){
      if (this.grid[3] == 1)
        return {"status": 1, "whowon": 1};
      else 
        return {"status": 1, "whowon": 0};
    }

    if ((this.grid[6] == this.grid[7]) && (this.grid[7] == this.grid[8]) && this.grid[6] != -1){
      if (this.grid[6] == 1)
        return {"status": 1, "whowon": 1};
      else 
        return {"status": 1, "whowon": 0};
    }


    if ((this.grid[0] == this.grid[3]) && (this.grid[3] == this.grid[6]) && this.grid[0] != -1){
      if (this.grid[0] == 1)
        return {"status": 1, "whowon": 1};
      else 
        return {"status": 1, "whowon": 0};
    }

    if ((this.grid[1] == this.grid[4]) && (this.grid[4] == this.grid[7]) && this.grid[1] != -1){
      if (this.grid[1] == 1)
        return {"status": 1, "whowon": 1};
      else 
        return {"status": 1, "whowon": 0};
    }

    if ((this.grid[2] == this.grid[5]) && (this.grid[5] == this.grid[8]) && this.grid[2] != -1){
      if (this.grid[2] == 1)
        return {"status": 1, "whowon": 1};
      else 
        return {"status": 1, "whowon": 0};
    }

    if ((this.grid[0] == this.grid[4]) && (this.grid[4] == this.grid[8]) && this.grid[0] != -1){
      if (this.grid[0] == 1)
        return {"status": 1, "whowon": 1};
      else 
        return {"status": 1, "whowon": 0};
    }


    if ((this.grid[2] == this.grid[4]) && (this.grid[4] == this.grid[6]) && this.grid[2] != -1){
      if (this.grid[2] == 1)
        return {"status": 1, "whowon": 1};
      else 
        return {"status": 1, "whowon": 0};
    }

    return {"status": 0, "whowon": -1};
  }

  onClick(el: Element){
    if (this.myTurn == true && this.game == true){
      el.innerHTML = 'X'
      this.grid[el.id] = 1
      el.classList.add("disabled")  
      el.setAttribute('disabled','')
      this.myWebSocket.next({ grid: el.id.toString(), action: 'update_grid', message: ''});
      this.myTurn = false;
      let result = this.check()
      if(result["status"] == 1){
        if(result['whowon'] == 1)
          alert("You won!")
        else
          alert("You lost!")

        this.resetgrid()
      }

      if (!this.grid.includes(-1)){
        alert("No one win")
        this.resetgrid()
      }
    }

  }

  ngOnInit() {
    this.titleService.setTitle('TicTacToe');
  }

  ngOnDestroy(){
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }

  refreshToken() {
    if (this.authService.isLoggedIn)
      this.authService.refreshToken();
  }

}
