import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'niceDateFormatPipe'
})
export class NiceDateFormatPipePipe implements PipeTransform {

  transform(value: string) {

    var datePipe = new DatePipe("en-US");
    var current_year = datePipe.transform(value, 'MMM d');
    var not_current_year = datePipe.transform(value, 'MMM d, yyyy');
    var date = new Date(value),
    today = new Date(),
    diff = ((date.getTime() - (new Date()).getTime()) / 1000),
    daydiff = Math.ceil(diff / 86400);

    if (isNaN(daydiff))
      return '';

    return daydiff < 0 && "Due" ||    
    daydiff == 0 && "Today" ||
    daydiff == 1 && "Tmw" ||
    daydiff < 7 && datePipe.transform(value, 'EEE') ||

    // daydiff < 14 && daydiff + " days" ||
    // daydiff < 14 && Math.floor(daydiff / 7) + "week" ||
    // daydiff < 31 && Math.floor(daydiff / 7) + " weeks" ||
    today.getFullYear() === date.getFullYear() && current_year ||
    not_current_year;
    


 }

}
