import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ThemeService } from '../shared/theme.service';
import { fromEvent, Subscription } from 'rxjs';
import { WeatherService } from '../shared/weather.service';
import { Weather } from './weather/weather';
import { NewsService } from './news/news.service';
import { News } from './news/News';
import { AuthService } from '../shared/auth.service';
import { Title } from '@angular/platform-browser';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy{
  @ViewChild("search") search: ElementRef
  darkmode: boolean;
  subscriptions: Subscription[] = [];
  cities: Weather[];
  news: News[] = [];
  ReutersNews: News[] = [];
  MediaStackNews: News[] = [];
  showAddWeatherStatus: boolean = false;

  inputTerm: string;
  searchTerm: string;

  constructor(private titleService: Title, private themeService: ThemeService, private weatherService: WeatherService, private newsService: NewsService, 
    private authService: AuthService) {
   }

  ngOnInit() {
    this.titleService.setTitle('Dashboard');
    this.subscriptions.push(this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    }));


    this.subscriptions.push(this.weatherService.get_my_cities().subscribe(cities =>{
      this.cities = cities;
    }));

    this.subscriptions.push(this.newsService.getNews().subscribe(news => {
      //Remove news dupliates
      this.MediaStackNews = news.filter((n, index, self) =>
        index === self.findIndex((t) => (
          t.title === n.title
        ))
      );
      this.news.push(...this.MediaStackNews)
    }));

    this.subscriptions.push(this.newsService.getReuters().subscribe(news => {
      //Remove news dupliates
      this.ReutersNews = news.filter((n, index, self) =>
        index === self.findIndex((t) => (
          t.title === n.title
        ))
      );

      this.news.push(...this.ReutersNews)
    }));
  }

  ngOnDestroy(){
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    })
  }

  ngAfterViewInit(): void{
    this.subscriptions.push(fromEvent(this.search.nativeElement, 'keyup').pipe(debounceTime(1000))
    .subscribe(_ => {
      this.searchTerm = this.inputTerm
      this.refreshToken()
    }
    ));  
  }

  onDeleteCity(id: number){
    this.refreshToken();
    this.weatherService.removeCity(id).subscribe(data =>
    {
      this.cities = this.cities.filter(c => {
        return c.id !== id
      })}  
      
    );
  }

  addCity(city: string){  
    this.weatherService.addCity(city).subscribe(data => {
      this.refreshToken();
      this.cities.push({...data})
    });
  }

  showAddWeather(status: boolean = false){
    this.showAddWeatherStatus = !this.showAddWeatherStatus;
    this.refreshToken();
    
  }
  refreshToken(){
    if(this.authService.isLoggedIn)
      this.authService.refreshToken();
  }

  onLoadUpdateLayout(){}

}
