<form class="assignee-form">
    <app-initial-icon *ngIf="user" [user]="user" [size]="size"></app-initial-icon>
    <div class="myautocomplete">
      <input type="text"  [formControl]="myControl" [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="assigneeSelected($event)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option.first_name}}
        </mat-option>
      </mat-autocomplete>
    </div>
    <mat-icon (click)="clear()">clear</mat-icon>
</form>