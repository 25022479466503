import { Component, OnInit, Input, Output, EventEmitter, OnDestroy  } from '@angular/core';
import { Subscription } from 'rxjs';
import { ThemeService } from '../theme.service';

@Component({
  selector: 'app-textareaAutoGrow',
  templateUrl: './textareaAutoGrow.component.html',
  styleUrls: ['./textareaAutoGrow.component.scss']
})
export class TextareaAutoGrowComponent implements OnInit, OnDestroy {
  @Input('inBoundData') inBoundData: string;
  @Output() outBoundData = new EventEmitter<string>();
  darkmode: boolean = false;
  @Input('darkOk') darkOk: boolean = false;

  subscriptions: Subscription[] = []

  constructor(private themeService: ThemeService) { }
  ngOnInit() {
    this.subscriptions.push(this.themeService.darkmode.subscribe(status => {
      this.darkmode = status;
    }))
  }

  onUpdate(){
    this.outBoundData.emit(this.inBoundData);
  }

  ngOnDestroy(){
    this.subscriptions.map(s => s.unsubscribe())
  }
}
